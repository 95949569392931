@import '~assets/styles/variables';

.container {
  height: 100%;
  width: 100%;
  display: flex;

  &.big {
    height: 150px;
  }

  .input__wrapper {
    width: 100%;
    height: 75%;
    border: 1px solid $primary-light;
    box-sizing: border-box;
    border-radius: 28px;

    &.big {
      height: 150px;
    }

    input {
      display: none;
    }

    .upload {
      &__wrapper {
        width: 100%;
        height: 75%;
        display: flex;
        align-items: inherit;
        justify-content: flex-start;
      }
    }
  }

  .trash_button {
    margin: 3px;
    right: 17px;
    position: absolute;
    color: rgba(91, 118, 139, 0.4);
    background-color: transparent;
  }

  .top {
    width: 100%;
    height: 28px;
    background-color: #f1f3ff;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .upload__options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .option__item {
      cursor: pointer;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: none;
      color: $text-light;
      margin: 10px;
    }
  }
}

.preview {
  width: 64px;
  height: 116px;
  object-fit: cover;
}

.preview__view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkbox {
  display: flex;

  input[type='checkbox'][id^='myCheckbox'] {
    display: none;
  }

  label {
    border: 1px solid #fff;
    border-radius: 0;
    display: block;
    position: relative;
    margin: 12px;
    cursor: pointer;
  }

  label:before {
    background-color: white;
    color: white;
    content: ' ';
    display: block;
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  :checked + label {
    border-color: #ddd;
  }

  :checked + label:before {
    content: '✓';
    background-color: rgba(33, 75, 165, 0.6);
    transform: scale(1);
    font-size: 16px;
    line-height: 15px;
  }

  :checked + label img {
    border: 3px solid $primary-main;
    z-index: -1;
  }
}
