.container {
	width: 100%;
	height: min-content;

	.dashboard {
		&__grid {
			display: flex;
			justify-content: space-between;
			gap: 15px;
		}

		&__main {
			max-width: 1095px;
			flex: 1 1 100%;
			display: flex;
			flex-direction: column;
			gap: 30px;
		}

		&__rowMain {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
		}

		&__rowTitle {
			margin-bottom: 18px;
			font-size: 18px;
			line-height: 18px;
			letter-spacing: -0.208px;
			color: #466c88;
		}

		&__sidebar {
			max-width: 328px;
			width: 100%;
			margin-top: 36px;
		}
	}
}
