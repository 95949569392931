.container {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  z-index: 15;
  position: relative;

  .filter {
    max-width: 300px;
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;

    button {
      margin-left: 15px;
    }
  }
}
