.group {
  display: flex;
  justify-content: flex-start;
  row-gap: 12px;

  :global {
    .MuiRadio-colorSecondary.Mui-checked {
      color: #214ba5;
    }

    .MuiFormControlLabel-label {
      display: flex;
      justify-content: flex-start;
    }
  }
}
