.banner {
  display: none;
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1000px) {
  .banner {
    display: flex;
  }
}
