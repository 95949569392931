@import '~assets/styles/variables';

.editTag__modal {
  & > div:first-child {
    background-color: rgba(241, 243, 255, 0.8);
  }

  & > div:nth-child(3) > div {
    border-radius: 10px;
  }

  .content {
    width: 440px;
    height: 260px;
    padding: 12px;
    border-radius: 10px;
    background: $gradients-orange;

    &__title {
      font-size: 20px;
      margin-bottom: 22px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__close__button {
      position: absolute;
      top: 11px;
      right: 11px;
      background: transparent;
    }
  }
}
