@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
  line-height: 18px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  border-radius: 8px;
  position: relative;
  @include changeOpacityToVisibleAnimation;

  &:nth-child(2n) {
    background-color: $container-bg;
  }

  &.suspend {
    background-color: rgba(247, 157, 84, 0.7);
    border: 1px solid #ff853e;
  }

  &.opportunities {
    @extend .container;
    display: grid;
    grid-template-columns: 60px 2.2fr 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    min-width: 1200px;
    grid-gap: 10px;
  }

  &.staged_opportunities {
    @extend .container;
    display: grid;
    grid-template-columns: 60px 2fr 1fr 1fr 1fr 1fr;
    min-width: 1200px;
    grid-gap: 4px;
  }

  &.opportunities_without_action {
    @extend .container;
    display: grid;
    grid-template-columns: 60px 2.4fr 1.3fr 0.8fr 0.8fr 1fr 1fr 1fr 0.5fr;
    min-width: 1000px;
    grid-gap: 0.7%;
  }

  &.volunteers {
    @extend .container;
    display: grid;
    grid-template-columns: 60px 13% 11% 4% 7% 8% 11% 9% 9% 9% 5%;
    grid-gap: 10px;
    min-width: 1070px;
  }

  &.students {
    @extend .container;
    display: grid;
    grid-template-columns: 60px 14% 10% 5% 5% 8% 5% 11% 9% 8% 8% 5%;
    grid-gap: 0.7%;
    min-width: 1280px;
  }

  &.signup_requests {
    @extend .container;
    display: grid;
    grid-template-columns: 60px 2fr 2fr 2fr 1fr 1fr 1fr 2fr;
    grid-gap: 10px;
  }

  &.past_oppo {
    @extend .container;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 60px;
    grid-gap: 6px;
  }

  &.possible_volunteers {
    @extend .container;
    display: grid;
    grid-template-columns: 64px 180px 54px 54px 1fr 1fr 94px 112px;
    grid-gap: 6px;
  }

  &.possible_volunteers_pending {
    @extend .container;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 2fr 1fr 112px;
    grid-gap: 6px;
  }

  &.smart_group {
    @extend .container;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1fr 1fr 2fr 2fr 2fr;
    grid-gap: 10px;
  }

  &.smart_group_small {
    @extend .container;
    display: grid;
    grid-template-columns: 0.5fr 1fr 2fr 1fr 1fr 2fr 1fr 2.3fr;
    grid-gap: 14px;
  }

  &.members {
    @extend .container;
    display: grid;
    grid-template-columns: 24% 24% 24% 25%;
    grid-gap: 10px;
  }

  &.inviteStudents {
    @extend .container;
    display: grid;
    grid-template-columns: 1fr 1fr 140px 100px 92px 60px 60px;
  }

  &.inviteOrganizationStudents {
    @extend .container;
    display: grid;
    grid-template-columns: 1fr 1fr 140px 100px 92px 60px;
  }

  &.notactiveClass {
    background-color: #cecece;
  }

  &.overtariffColor {
    background-color: #ff00002e;
  }
}
