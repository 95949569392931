.limit {
  width: 100%;
  margin: 4px 0px;
  &__text {
    font-weight: 400;
    font-size: 10px;
    color: #214ba5;
  }
}

.centered {
  display: flex;
  justify-content: center;
}
