@import '~assets/styles/mixins';

.form {
	@include changeOpacityToVisibleAnimation;
	width: 250px;
	position: absolute;
	right: -2px;
	top: -2px;
	padding: 10px;
	padding-top: 40px;
	padding-inline: 20px;
	background-color: #fff;
	border-radius: 10px;

	&.close {
		@include changeOpacityToUnvisibleAnimation;
	}

	.label {
		@include popupLabel;
	}

	.button_cross {
		position: absolute;
		right: 15px;
		top: 10px;
	}
}
