@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.container {
  @include changeOpacityToVisibleAnimation;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
  background-color: #fff;
  padding: 30px 36px;
  margin-bottom: 1.3vh;

  .title {
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
    @include lineThreeDots();
    white-space: unset;

    img {
      display: block;
      width: 16px;
      height: 14px;
      object-fit: contain;
      margin-left: 2px;
      align-self: center;
      flex: 0 0 22px;
    }
  }

  .time,
  .location {
    svg {
      margin-right: 12px;
    }
  }

  .time {
    margin-bottom: 8px;

    svg {
      margin-right: 12px;
    }
  }

  .location {
    margin-bottom: 1.9vh;
  }
}

.mark__wrapper {
  display: flex;
  max-width: 80px;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  margin-bottom: 4px;
}

.admin__mark {
  background-color: #ffbf00;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.3px;
  margin: 0;
  padding: 4px;
  border-radius: 8px;
  font-weight: 400;
}

.manual__mark {
  display: flex;
  align-items: center;
}
