@import '~assets/styles/variables';

.container {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  background-color: $white;

  .filter {
    max-width: 300px;
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;

    button {
      margin-left: 15px;
    }

    .button__hashtag {
      width: 22px;
      height: 22px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
