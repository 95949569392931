@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
  @include changeOpacityToVisibleAnimation;
  max-width: 328px;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: transparent;
  z-index: 10;

  &.disableSticky {
    position: initial;
  }

  .placeholder {
    @include changeOpacityToVisibleAnimation;
    color: $primary-main;
  }

  .date_selected {
    margin-top: 2.5vh;
    margin-bottom: 1.8vh;
    line-height: 18px;
    letter-spacing: -0.208px;
  }
  .tape_list {
    @include changeOpacityToVisibleAnimation;
  }
}
.loader {
  margin-top: 3vh;
}
