.edit__hash__form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_field {
    //margin-bottom: 30px;
    & > label {
      margin-bottom: 2px;
    }
  }
}

.error {
  position: absolute;
  top: 40px;
  left: 0px;
  display: flex;
  flex-direction: column;

  &_formik {
    top: 60px;
  }
}
