@import "~assets/styles/variables";
@import "~assets/styles/mixins";

.upload {
  @include changeOpacityToVisibleAnimation;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: flex-end;
  width: max-content;
  padding-top: 17px;
  margin: 0 0 3.5vh 50px;

  &__text {
    font-size: 18px;
    line-height: 23px !important;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;

    &.clear_all {
      margin-left: 130px;
      margin-right: 50px;
    }
  }

  input {
    display: none;
  }

  &__info {
    @include changeOpacityToVisibleAnimation;
    color: $text-light;
    text-decoration: solid;
    font-size: 12px;
  }
}
