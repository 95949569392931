.scrollIcon {
  cursor: pointer;
  position: fixed;
  right: 28px;
  bottom: 24px;

  opacity: 0;
  transition: opacity 0.3s ease;

  svg {
    width: 30px;
    height: 30px;
  }

  &_inPopup,
  &_inSmartGroup {
    position: absolute;
    width: 30px;
    height: 30px;

    svg {
      position: fixed;
    }
  }

  &_inSmartGroup {
    z-index: 99;
  }

  &_visible {
    opacity: 1;
  }
}
