.LangSwitch {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}
.LangSwitch__image {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}
.LangSwitch__currentLang {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.208px;
  color: #214ba5;
}
.LangSwitch__arrow {
  transform: rotate(90deg);
}
.wrap {
  width: 75px;
  span,
  div {
    color: rgb(33, 75, 165);
  }
}
