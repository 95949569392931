@import '~assets/styles/mixins';

.absolute_disable {
  position: static;
  height: auto;
}

.appeal {
  max-width: 495px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6.3vh;

  span:first-child {
    margin-bottom: 3vh;
  }

  .subtitle {
    display: block;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.01em;
    text-align: center;
    margin-bottom: 4vh;
  }
}

.options {
  max-width: 260px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2vh;
}

.icon {
  display: none;
  cursor: pointer;
  margin: 4px 0;
  height: 44px;
  width: 152px;
}

.close {
  @include changeOpacityToUnvisibleAnimation;
}

@media (max-width: 500px) {
  .options {
    display: none;
  }

  .icon {
    display: block;
  }
}
