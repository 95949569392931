@import '~assets/styles/mixins';

.input__text {
  position: relative;
  width: 100%;

  input {
    @include inputMain;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
    }

    &:not(:focus) {
      & + .input__text_edit {
        opacity: 1;
      }
    }
  }

  &_edit {
    @include inputEdit;
  }
}
