@import '~assets/styles/variables';

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 3vh;
	color: $text-light;
	font-size: 18px;
	line-height: 20px;
	text-align: center;

	&.disabled {
		pointer-events: none;
	}

	.createAccount {
		&__link,
		&__link:visited {
			display: block;
			color: $text-light;
			line-height: 20px;
		}
	}

	.disabled {
		pointer-events: none;
	}
}
