@import '~assets/styles/mixins';

.input_text {
  position: relative;
  input {
    @include inputMain;

    &:not(:focus) {
      & + .input_text_edit {
        opacity: 1;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &_edit {
    @include inputEdit;
  }
}
