.container {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 300px;
  z-index: 15;
  position: relative;
  margin: 20px;
}
