@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.208px;
  color: #1c2f4c;
  margin-bottom: 10px;
  margin-top: 27px;
  text-align: center;
}

.subtitle {
  width: 100%;
  max-width: 56%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  color: $primary-main;

  .subtitle__pre {
    margin-right: 6px;
    color: $text-main;
  }
}

.form__container {
  max-width: 530px;
  width: 100%;
  margin-top: 2.5vh;

  .form {
    .label {
      @include popupLabel;

      &__unvisible {
        @include popupLabel;
        opacity: 0;
        pointer-events: none;
      }
    }

    &__pickers {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.3vh;

      &_date {
        max-width: 165px;
        width: 100%;
      }

      &_time {
        max-width: 345px;
        margin-left: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > div {
          z-index: 2;
        }

        .timer {
          max-width: 165px;
          width: 100%;
        }
      }
    }

    &__location {
      margin-bottom: 1.3vh;

      div {
        z-index: 0;
        height: auto;
      }

      input {
        @include changeOpacityToVisibleAnimation;
      }
    }
  }
}
