.statuses {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    &__filters {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }

  &__search {
    height: 40px;
    & > div:first-child {
      left: 21px;
    }

    input {
      height: 100%;
      padding-left: 52px;
      padding-right: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.2px;
    }
  }

  &__create {
    max-width: 111px !important;
  }
}