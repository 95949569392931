.feed__sidebar {
  position: sticky;
  top: 143px;
  height: 70vh;
}
.feed__sidebarSearch,
.feed__sidebarFilter {
  padding: 28px;
  width: 348px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  ul {
    padding: 0;
    margin: 0;
  }
}
.feed__sidebarFilter {
  padding: 28px 0;
}
.sidebarTitle {
  display: flex;
  justify-content: space-between;
  padding: 0 28px;
  margin-bottom: 20px;
  cursor: pointer;
}
