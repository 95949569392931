@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.form {
  .label {
    @include popupLabel;

    &__unvisible {
      @include popupLabel;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__hashtag__wrapper {
    display: flex;
    position: relative;
    margin-bottom: 20px;
  }

  &__hashtag_label {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: $text-main;
  }

  &__pickers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.3vh;

    &_date {
      width: 60%;
    }

    &_time {
      max-width: 345px;
      margin-left: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > div {
        z-index: 2;
        & > span {
          position: absolute;
          bottom: -20px;
        }
      }

      .timer {
        width: 100%;
      }
    }
  }

  &__location {
    margin-bottom: 1.3vh;

    div {
      z-index: 0;
      height: auto;
    }

    input {
      @include changeOpacityToVisibleAnimation;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.208px;
  color: #1c2f4c;
  margin-bottom: 27px;
  margin-top: 27px;
  text-align: center;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.21px;
  text-align: left;
  color: #5b768b;
  padding-bottom: 30px;
}

.divider {
  border-top: 1px solid #c6ccfe;
  margin-bottom: 70px;
  margin-top: 70px;
}
