.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  border: 1px solid #c6ccfe;
  max-width: 320px;
  width: 100%;
  background: white;
  height: fit-content;
  padding: 16px 20px;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 101;
    background-color: rgba(151, 151, 151, 0.6);
  }
}

.cross {
  > button {
    display: none;
  }
}

.group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  color: #5b768b;

  :global {
    .MuiRadio-colorSecondary.Mui-checked {
      color: #214ba5;
    }
  }
}

.title {
  color: #5b768b;
  font-weight: 600;
}

.button {
  font-size: 16px !important;

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
  }
}

.label {
  justify-content: flex-start;
}
