@import '~assets/styles/variables';

.createTag__modal {
  & > div:first-child {
    background-color: rgba(241, 243, 255, 0.8);
  }

  & > div:nth-child(3) > div {
    border-radius: 10px;
    max-width: 50vw;
  }

  .content {
    border-radius: 10px;
    width: 50vw;
    height: 80vh;
    background: $gradients-orange;
    overflow-x: hidden;
    padding-bottom: 20px;

    &__close__button {
      position: absolute;
      top: 11px;
      right: 11px;
      background: transparent;
    }

    &__background__image {
      border-radius: inherit;
      background-image: url('~assets/image/onboarding/contact.svg');
      width: 100%;
      height: 40%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__missingHashtags__section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        margin-top: 20px;
      }
    }

    &__loader {
      margin-top: 120px;
    }
  }
}
