@import '~assets/styles/mixins';

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  transition: all 0.2s ease;

  .icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  &.user,
  &.heart,
  &.filter,
  &.edit,
  &.settings,
  &.calendar {
    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &.heart {
    @include changeOpacityToVisibleAnimation;
  }

  &.eye {
    fill: #c6ccfe;
  }

  &.pencil,
  &.trash,
  &.eye {
    width: 12px;
    height: 12px;
  }

  &.add_volunteer {
    width: 40px;
    height: 40px;
  }

  &.clip {
    height: 25px;
    width: 25px;
  }

  &.send {
    height: 20px;
    width: 20px;
  }
}
