@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.content {
  &__container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 3.8vh 2.7vw;
    background-color: $container-bg;
    @include changeOpacityToVisibleAnimation;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    z-index: 999;
  }

  &__wrapper {
    max-width: 1225px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    margin-top: 4vh;
    position: relative;
  }
}
