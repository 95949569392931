@import "~assets/styles/variables";

.content {
  &__wrapper {
    display: flex;
    column-gap: 12px;
    padding-top: 8px;
  }
}

.input {
  height: 40px;

  //to remove arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield; /* Firefox */
    font-size: 14px;
    font-family: "Rubik", sans-serif;
  }

  & input {
    text-align: center;
    padding: 0;
    height: 32px;
    width: 52px;
  }
}

.container {
  width: fit-content;
  height: 32px;
  min-height: auto;
}

.button {
  //important is necessary to restyle it
  background-color: #f1f3ff !important;
  color: $primary-main !important;
  border: none !important;
  min-width: 0 !important;
  font-size: 14px !important;
  height: 32px !important;

  &_active {
    background-color: $primary-light !important;
    color: $primary-dark !important;
  }
}

.error {
  position: absolute;
  left: 0;
  top: 92px;
  margin-left: 12px;
}
