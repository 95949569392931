@import "~assets/styles/variables";
@import "~assets/styles/mixins";

.mainContent {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: $text-light;

  &__contactLink {
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.208px;
    text-decoration-line: underline;
    color: $text-light;
    svg {
      margin-right: 15px;
      margin-left: 25px;
    }
  }

  &__messageLink {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}



.userName {
  @include threeDots();
  margin: 0 15px;
}
