@import '~assets/styles/mixins';

.container {
  position: relative;
  width: 100%;
}

.input {
  @include inputMain;

  &_pencilHidden {
    padding-right: 24px;
  }

  &:focus + .edit {
    opacity: 0;
  }

  &:disabled::placeholder {
    color: #b1b7bb;
  }
}

.edit {
  @include inputEdit;
}
