@import '~assets/styles/variables';

.wrap {
  position: relative;
}

.disabled {
  background-color: #eee;
  border-radius: 30px;
  color: #b1b7bb;
  pointer-events: none;
}

.googleAct {
  font-size: 18px;
  width: 100%;
  height: 40px;
  padding: 11px 24px 11px 38px;
  border-radius: 30px;
  border: 1px solid $primary-light;
  text-overflow: ellipsis;
  box-shadow: none;
  z-index: 2;
  transition: all 0.2s ease;
  color: $text-main;

  &[dir='rtl'] {
    padding: 11px 38px 11px 24px;
  }

  &::placeholder {
    color: #5b768b;
    opacity: 1;
  }

  &:active,
  &:focus {
    outline: none;
    border-color: $secondary-main;
  }

  :not(:placeholder-shown) {
    border-color: green;
  }

  position: relative;
}

.search_icon {
  position: absolute;
  left: 15px;
  top: 12px;
  z-index: 3;

  &[dir='rtl'] {
    left: unset;
    right: 15px;
  }
}

.pac-container {
  border-radius: 30px;
  border: 1px solid $primary-light;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 0;
  box-shadow: none;

  &:after {
    display: none;
  }

  .pac-item {
    padding: 5px 10px;
    padding-left: 17px;
    cursor: pointer;

    &:hover {
      background-color: $primary-bg;
    }

    &:last-child {
      padding-bottom: 9px;
    }
  }

  .pac-icon.pac-icon-marker {
    width: 11px;
    height: 14px;
    content: url('~assets/image/location.svg');
    object-fit: contain;
    background: none;
  }

  .pac-matched {
    font-weight: inherit;
  }
}

.pac-target-input:not(:-webkit-autofill) {
  animation: none;
  outline: none;
}
