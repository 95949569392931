@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.main {
	width: 100%;
	display: grid;
	grid-template-rows: 100px 1fr;
	grid-template-columns: minmax(100px, 309px) 1fr;

	&__content {
		@include changeOpacityToVisibleAnimation;
		width: 100%;
		height: calc(100vh - 100px);
		background: $container-bg;
		overflow: auto;
		position: relative;

		h1 {
			margin: 0;
			margin-bottom: 30px;
		}

		&__container {
			width: 100%;
			display: flex;
			grid-column-start: 1;
			grid-column-end: 3;
		}
	}

	* {
		box-sizing: border-box;
	}
}
