.birthDate {
  width: 100%;
}

.gender {
  z-index: 4;
}

.upload_photo {
  margin-top: 25px;
  height: 75px;
}

.terms {
  display: flex;
  flex-direction: column;
}
