@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.main {
	max-width: 350px;
	width: 100%;
	// height: max-content;
	position: sticky;
	top: 0;
	background-color: transparent;
	z-index: 10;
	display: flex;
	justify-content: center;
	@include changeOpacityToVisibleAnimation;

	.content {
		background-color: #fff;
		// height: 300px;
		max-width: 350px;
		width: 100%;
		// border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.separator {
			width: 100%;
			border: none;
			height: 1px;
			background-color: $primary-bg;
		}

		&__filters {
			width: 100%;
			padding: 10px 25px;

			&.disable_padding_side {
				padding: 5px 0;
				// max-height: 680px;
				overflow-y: auto;
			}
		}
	}
}
