@import "~assets/styles/variables";
@import "~assets/styles/mixins";

button.outlined {
  @include font-button;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 21px;
  padding: 4px 6px;
  border: 1px solid $primary-light;
  border-radius: 50px;
  background: $white;
  font-size: 10px;
  color: $primary-main;
  transition: all 0.2s ease;

  &:enabled:hover,
  &:enabled:focus {
    color: $primary-main;
    background-color: rgba($primary-light, 0.2);
    box-shadow: none;
    border: 1px solid $primary-light;
  }
}
