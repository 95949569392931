@import '~assets/styles/variables';

.wrapper {
	text-align: center;
}
.main,
main:visited {
	max-width: 165px;
	width: 100%;
	display: block;
	color: $text-light;
	align-self: flex-start;
}
