@import "~assets/styles/variables";

.item {
  &__toping {
    width: 100%;
    height: 9px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $primary-light02;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: $text-dark;
  }

  &__price {
    margin-top: 26px;
    justify-self: flex-start;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &__number {
      font-weight: 300;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -5px;
      color: $primary-main;
    }

    &__unit {
      width: 140px;
      margin-left: 10px;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      color: $text-light;
    }
  }
}
