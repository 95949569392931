@import '~assets/styles/mixins';

.messaging-create-channel__user-results {
  position: absolute;
  top: 79%;
  left: 35px;
  width: 295px;
  max-height: 400px;
  overflow-y: scroll;
}

.messaging-create-channel__user-result {
  display: flex;

  &__details {
    word-wrap: break-word;
    @include multilineThreeDots(3);
  }
}


.messaging__channel-list__header {
  position: sticky;
  top: 0;
}

.messaging__channel-list__controls {
  display: flex; 
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.suspendResultsInChat {
  background-color: #f79d54;
  opacity: 0.7;
  border: 1px solid #ff853e;
}
