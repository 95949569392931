@import '~assets/styles/mixins';

.fullName {
  width: 100%;
  max-width: unset !important;


  &__content {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;


    & > span {
      @include multilineThreeDots(2);
      text-wrap: wrap;
      max-width: 100%;
    }

    .text {
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        line-height: 20px;
        font-weight: bold;
        text-align: left;
      }
    }
  }
}
