@import '~assets/styles/variables';

.button_close {
  transform: translate(0px, -12px) rotate(90deg);
  outline: none;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.2s ease;
  padding: 9px;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    background-color: $secondary-main;
  }

  &__icon {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
}

.popup {
  cursor: auto;
}

.disabled {
  display: none;
}
