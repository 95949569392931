@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.feed_item {
  background-color: #ffffff;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  padding: 28px 34px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  &.suspended {
    background-color: rgba(206, 206, 206, 0.7);
  }
}

.item__main {
  display: grid;
  grid-template-columns: minmax(0, 3fr) 2fr;
  grid-column-gap: 20px;
  column-gap: 20px;
  width: 100%;
}
.item__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.208px;
  color: #1c2f4c;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
    width: 100%;
    overflow: hidden;
  }

  img {
    width: 25px;
    height: 26px;
    object-fit: contain;
    margin-left: 7px;
  }
}
.item__feedOrganizator {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #5b768b;
  grid-column: 1 / span 2;
  word-wrap: break-word;
  @include multilineThreeDots(2);
}

.item__feed__hashtag {
  max-width: 334px;
  margin-top: 4px;
  font-style: italic;
  color: $primary-main;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.feedStatus {
  display: flex;
  grid-column: 1 / span 2;
  margin-top: 18px;
  gap: 20px;
}
.feedStatus__ico,
.feedClock__ico {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    text-align: center;
  }
}

.item__feedClock {
  .item__feedClock_asd {
    flex: 1 1 auto;
    display: flex;
  }
  width: 110px;
  height: 22px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #f1f3ff;
  color: #214ba5;
  .feedClock__ico {
    background: #214ba5;
    &::before {
      display: inline-block;
      width: 5px;
      height: 7px;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
      top: -4px;
      left: 4px;
    }
  }
  .feedClock__text {
    font-size: 10px;
    line-height: 22px;
    margin-left: 7px;
  }
}
.item__details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.item__feedParty {
  display: flex;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1c2f4c;
}

.popup__custom {
  position: absolute;
  width: 220px;
  min-height: 157px;
  background: #ffffff;
  border: 1px solid #f1f3ff;
  box-shadow: -2px -2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 8px;
  bottom: 0;
  right: 0;
  padding: 25px 17px 17px 17px;
  user-select: none;

  a {
    display: flex;
    text-decoration: none;
    margin-bottom: 23px;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    line-height: 10px;
    transform: rotate(45deg);
    color: #214ba5;
    font-size: 20px;
  }

  .ico {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: #f1f3ff;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #214ba5;
    svg {
      display: block;
      width: 10px;
      height: 10px;
      path {
        fill: #214ba5;
      }
    }
    &.ico__close {
      position: relative;
      line-height: 10px;
      transform: rotate(45deg);
      font-size: 20px;
    }
  }
  .text {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #1c2f4c;
  }
}
.volunteersLimit__wrapper {
  min-width: '74px';
}

.manualOpportunity__mark {
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;

  &_admin {
    background-color: #ffbf00;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.3px;
    margin: 0;
    padding: 4px;
    border-radius: 8px;
    font-weight: 400;
  }

  &_manual {
    display: flex;
    align-items: center;
  }
}

.previous_completed_mark {
  path {
    fill: #214ba5;
  }
}
