.button {
	&_confirm {
		margin-top: 4vh !important;
		margin-bottom: 2vh !important;
	}
}

.title {
	margin-top: 2.5vh !important;
  margin-bottom: 0 !important;
}
