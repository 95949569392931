@import '~assets/styles/mixins';

.container {
	@include onboardingForm;
	@include changeOpacityToVisibleAnimation;
	max-width: 335px;
	width: 100%;

	.title__wrapper {
		display: flex;
		flex-direction: column;
		align-self: flex-start;
		margin-bottom: 1.4vh;

		.title {
			line-height: 26px;
			text-transform: capitalize;
		}
	}
}

@media screen and (max-width: 1000px){
  .container {
    max-width: 100%;
  }
}