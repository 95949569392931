@import '~assets/styles/variables';

.container {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    .label {
      display: block;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 10px;
      margin-right: 25px;

      &__action {
        font-size: 14px;
        line-height: 21px;
        color: $text-light;
        cursor: pointer;
      }
    }
  }
  .who {
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4px;

    label {
      .input {
        display: none;
      }
    }
  }
}
