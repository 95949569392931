@import '~assets/styles/variables';

@mixin multilineThreeDots($lineClamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-width: 600px;
}

.overflow__hidden {
  overflow: hidden !important;

  & table {
    display: none;
  }
}

.statuses {
  width: 100%;
  border-collapse: collapse;

  &__wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__table__row {
    height: 100px;
  }

  th {
    font-size: 12px;
    font-style: normal;
    padding: 10px 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.208px;
    color: $primary-main;
    background-color: $primary-bg;

    &:first-child {
      border-radius: 8px 0 0;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
    }
  }

  td {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.208px;
    padding: 10px 16px;
    min-height: 100px;
  }

  &_media {
    min-width: 108px;
  }
  &_caption {
    min-width: 334px;
  }

  &_link {
    min-width: 260px;
  }

  &_startDate, &_endDate {
    min-width: 240px;

    &_wrapper {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 30px;
      width: max-content;

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.3px;
      }
    }
  }

  &_startDate {
    &_wrapper {
      background-color: $primary-light02;
      color: $primary-main;
    }
  }

  &_endDate {
    &_wrapper {
      background-color: #FFF0DE;
      color: #E47C00;

      svg path {
        fill: #E47C00;
      }
    }
  }

  &_phase {
    min-width: 162px;
  }

  &_row_link {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 131%;
    letter-spacing: -0.18px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    color: #214ba5;
    cursor: pointer;
  }

  &_row_caption, &_row_link, &_row_phase {
    span, a {
      @include multilineThreeDots(2)
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
}

.pagination__loader {
  width: 40px;
  height: 40px;
}

.noItemsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 912px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 60px;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  background-color: #fff;


  &__gradient {
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 73%;
    background: linear-gradient(180deg, #fff4e4 0%, rgba(255, 244, 228, 0) 100%);
  }

  .noItemsImage {
    width: 320px;
    height: 320px;
    z-index: 2;
  }

  .noItemsTextContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    .noItemsTitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 26.4px;
      text-align: center;

      color: #1c2f4c;
    }

    .noItemsText {
      padding-bottom: 4vh;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: center;

      color: #000000;
    }
  }
}