@import '~assets/styles/mixins';

.content {
	@include loginContent;

	button {
		margin-top: 0;
		margin: 0 auto;
	}
}
