@import '~assets/styles/mixins';

.input__search {
	position: relative;
	z-index: 3;

	input {
		@include inputMain;
		padding-left: 34px;

		&:not(:focus) {
			& + .input__search_edit {
				opacity: 1;
			}
		}
	}

	&_edit {
		@include inputEdit;
	}

	&_icon {
		position: absolute;
		left: 15px;
		top: 12px;
		width: 14px;
		height: 14px;
		opacity: 1;
		transition: 0.2s;
		background-image: url('~assets/image/inputs/search.svg');
		cursor: pointer;
	}

	&.big {
		max-width: 260px;
		width: 100%;
		height: 40px;
	}
}
