@import '~assets/styles/variables';
@import '~assets/styles/mixins';

button.outlined {
  @include font-button;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 95px;
  min-width: 95px;
  padding: 4px 6px;
  border: 1px solid $primary-light;
  border-radius: 50px;
  background: $white;
  font-size: 10px;
  color: $primary-main;
  transition: all 0.2s ease;
  text-transform: none;
  button {
    width: 100%;
  }
}

.copy,
.chat {
  &__text {
    color: $text-main;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__icon {
    min-width: 16px;
    min-height: 16px;
    margin-right: 6px;
  }
}

.menu__item {
  &:hover {
    background-color: $primary-bg !important;
  }
}
