@import '~assets/styles/variables';

.fileUploadBtn {
	color: white;
	text-transform: uppercase;
	outline: none;
	background-color: #4aa1f3;
	font-weight: bold;
	padding: 8px 15px;
	margin-bottom: 5px;
}

.container {
	height: 53vh;
	max-height: 532px;

	.drop {
		&_info {
			align-self: flex-start;

			.label {
				text-decoration: underline;
				cursor: pointer;
				margin: 0 7px;
			}
			+ label {
				width: 100%;
				height: 100%;
			}
		}

		&__zone {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			align-self: stretch;
			height: 80%;
			border: 1px dashed $primary-main;
			border-radius: 10px;
			margin-top: 10px;
			flex: 1 1 auto;
			cursor: pointer;

			&_preview {
				border: none;
				overflow: hidden;
			}

			.icon_upload {
				width: 50px;
				height: 50px;
				background: url(~assets/image/upload2.svg) no-repeat center center;
				background-size: 100%;
				text-align: center;
				margin: 0 auto;
				padding-top: 30px;
			}

			.file {
				&_input {
					display: none;
				}

				&_properties {
					margin-top: 10px;
					font-size: 10px;
					line-height: 130%;
					align-items: center;
					text-align: center;
					color: $text-light;
				}
			}
		}
	}
}

.dropContainerBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 10px;
	cursor: pointer;

	span {
		display: inline;
		text-decoration: underline;
	}
}

.fileProgressBar {
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	display: inline-block;
	margin: 0px 10px 5px 5px;
	vertical-align: top;
	flex: 6 !important;
	margin-left: 50px;
	box-sizing: content-box;
	width: calc(100% - 50px);
}

.fileProgressBar div {
	height: auto;
	color: #fff;
	text-align: right;
	line-height: 15px;
	width: 0;
	background-color: #4caf50;
	border-radius: 3px;
	font-size: 13px;
}

.modal {
	z-index: 999;
	display: none;
	overflow: hidden;
}

.modal .overlay {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.66);
	position: absolute;
	top: 0;
	left: 0;
}

.modal .modalImage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	object-fit: cover;
	width: 100%;
	height: 300px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.modalImageText {
	position: absolute;
	color: red;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
}

.close {
	position: absolute;
	top: 15px;
	right: 35px;
	color: #f1f1f1;
	font-size: 40px;
	font-weight: bold;
	transition: 0.3s;
}

.uploadModal {
	z-index: 999;
	display: none;
	overflow: hidden;
}

.uploadModal .overlay {
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.66);
	position: absolute;
	top: 0;
	left: 0;
}

.progressContainer {
	background: white;
	width: 500px;
	height: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
}

.progressContainer span {
	display: flex;
	justify-content: center;
	padding-top: 20px;
	font-size: 20px;
}

.progress {
	width: 90%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #efefef;
	height: 20px;
	border-radius: 5px;
}

.progressBar {
	position: absolute;
	background-color: #4aa1f3;
	height: 20px;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: bold;
}

.error {
	color: red;
}
