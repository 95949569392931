@import '~assets/styles/variables';

.main {
	width: calc(100% - 20px);
	display: flex;
	flex-direction: column;
	height: 50px;

	.slider {
		width: 100%;
		padding: 0;

		&__rail {
			height: 1px;
			top: 5px;
			background: $primary-main;
		}

		&__track {
			height: 3px;
			top: 3px;
		}

		&__thumb {
			width: 20px;
			height: 20px;
			border-radius: 6px;
		}
	}
}
.slider__value {
	// margin-top: 24px;
	text-align: center;
	color: $text-light;
}
