.btn__dotDefault {
	width: 24px;
	height: 38px;
	background: transparent;
	border: none;
	outline: none;
}
.dot {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: #214ba5;
	margin-bottom: 3px;
	&:last-child {
		margin-bottom: 0;
	}
}
