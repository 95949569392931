.nav {
  position: absolute;
}

.wrap2 {
  justify-content: center;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.208px;
  color: #1c2f4c;
  margin-bottom: 27px;
  margin-top: 27px;
  text-align: center;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.21px;
  text-align: left;
  color: #5b768b;
  padding-bottom: 30px;
}

.description {
  max-width: 381px;
  width: 100%;
  text-align: center;
  margin-bottom: 61px;
  font-size: 22px;
  line-height: 130%;
  color: #1c2f4c;
}

.form__block {
  display: grid;
  max-width: 700px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 75px 75px 200px 75px;
  gap: 0 24px;
  align-items: start;

  .textarea {
    grid-column: 1 / span 2;
    margin-bottom: 10px;
    position: relative;
    z-index: 4;

    textarea {
      height: 158px;
    }
  }

  button {
    grid-column: 2;
    justify-self: end;
  }
}

@import '~assets/styles/mixins';
@import '~assets/styles/variables';

h2 {
  align-self: flex-start;
}

.form__container {
  max-width: 530px;
  width: 100%;
  margin-top: 2.5vh;

  .form {
    .label {
      @include popupLabel;

      &__unvisible {
        @include popupLabel;
        opacity: 0;
        pointer-events: none;
      }
    }

    &__pickers {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.3vh;

      &_date {
        max-width: 165px;
        width: 100%;
      }

      &_time {
        max-width: 345px;
        margin-left: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > div {
          z-index: 2;
        }

        .timer {
          max-width: 165px;
          width: 100%;
        }
      }
    }

    &__location {
      margin-bottom: 1.3vh;

      div {
        z-index: 0;
        height: auto;
      }

      input {
        @include changeOpacityToVisibleAnimation;
      }
    }
  }
}

.loader {
  width: 100%;
}

.divider {
  border-top: 1px solid #c6ccfe;
}
