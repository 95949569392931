.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 3vh;

	h1 {
		margin-bottom: 2.5vh;
	}

	.subtitle {
		font-size: 20px;
		margin-bottom: 2.5vh;
	}

	.loader {
		margin-top: 3vh;
	}

	.button {
		margin-top: 2.5vh;
	}

	.password_form {
		display: flex;
		flex-direction: column;
		align-items: center;

		& > div > div:first-child > input {
			margin-bottom: 2vh;
		}
	}
}
