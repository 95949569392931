.messaging__channel-header {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
  transform: matrix(1, 0, 0, 1, 0, 0);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  z-index: 1;
}

.messaging__channel-header .channel-header__name {
  /* flex: 1 0 auto; */
  font-weight: bold;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  width: 100%;
}

.str-chat.dark .messaging__channel-header {
  background: rgba(46, 48, 51, 0.98);
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
}

.str-chat.dark .messaging__channel-header .channel-header__name {
  color: rgba(255, 255, 255, 0.9);
}

.messaging__channel-header__right {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.messaging__channel-header__avatars {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
  margin-left: 20px;
}

.messaging__channel-header__avatars.two div:first-child {
  position: relative;
  right: 10px;
}

.messaging__channel-header__avatars.two div:nth-child(2) {
  position: relative;
  right: 14px;
}

.messaging__channel-header__avatars.two span {
  width: 20px;
  overflow: hidden;
}

.messaging__channel-header__avatars.three span {
  width: 20px;
  overflow: hidden;
}

.messaging__channel-header__avatars.four span:nth-child(2) {
  position: relative;
  right: 14px;
}

.channel-header__edit-input {
  width: 100%;
  font-family: Helvetica Neue, sans-serif;
  font-size: 17px;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}

.channel-header__edit-input::placeholder {
  opacity: 50%;
}

.str-chat.dark .channel-header__edit-input::placeholder {
  color: #ffffff;
}

.str-chat.dark .channel-header__edit-input {
  color: #ffffff;
}

#mobile-nav-icon {
  display: none;
}

@media screen and (max-width: 640px) {
  #mobile-nav-icon {
    display: block;
    padding-left: 5px;
  }

  #mobile-nav-icon.light svg path {
    fill: darkslategrey;
    stroke: darkslategrey;
    fill-opacity: 60%;
  }

  .messaging__channel-header__avatars {
    margin-left: 10px;
  }
}
