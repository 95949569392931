.form {
  width: 100%;
  position: relative;

  &__info {
    position: absolute;
    top: 194px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;

    & svg {
      width: 18px;
      height: 18px;

      & path {
        fill: #214ba5;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}
