@import '~assets/styles/variables';

.nav {
  display: flex;
  height: 60px;
  overflow-x: auto;
  overflow-y: hidden;
}

.oppoNav {
  width: 100%;
  position: absolute;
}
