@import '~assets/styles/variables';

.wrapper {
  position: relative;
  width: 100%;
  border: 1px solid $primary-light02;
  border-radius: 10px;
}

.main {
  width: 100%;
  padding: 14px;
}

.textarea {
  width: 100%;
  padding: 14px 14px 0;
  display: block;
  outline: none;
  resize: none;
  border: none;
  font-size: 16px;
}

.bottom {
  height: 64px;
  width: 100%;
  padding-inline: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
  border-top: 1px solid $primary-light02;

  &_error {
    margin-bottom: 10px;
  }
}

.imagePreview {
  flex-grow: 1;
  height: 88%;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: auto;

  &__uploaded {
    position: relative;
    border-radius: 10px;
    width: 40px;
    height: 40px;

    &::after {
      position: absolute;
      content: '';
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: rgb(54, 62, 75);
      background: linear-gradient(0deg, rgba(54, 62, 75, 0) 51%, rgba(54, 62, 75, 0.6) 100%);
      border-radius: inherit;
    }

    & > img,
    & > video {
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }

    &__buttonRemove {
      position: absolute;
      z-index: 3;
      right: 1px;
      top: 1px;
      transform: rotate(90deg);
      outline: none;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
      transition: all 0.2s ease;
      padding: 9px;
      width: 17px;
      height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: $secondary-main;
      }

      &__icon {
        width: 12px;
        height: 12px;
        object-fit: contain;
      }
    }
  }
}

.hidden {
  display: none;
}

.loader {
  margin: 0;
}

.error {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #d00074;
  padding-inline: 28px;
}
