@import '~assets/styles/variables';

.button {
  width: 26%;
  height: 100%;
  color: $text-light;
  background: transparent;
  border: none;
  padding: 0;

  &_disabled {
    cursor: not-allowed;
    color: $text-dark;
  }
}
