@import '~assets/styles/mixins';

.container {
  @include orgPage;
}

.list {
  height: 75%;

  &__wrapper {
    height: 100%;
    overflow-y: auto;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    position: relative;
    align-items: center;
    z-index: 2;

    button {
      margin-top: 0 !important;
    }
  }

  button {
    margin-top: 2.5vh;
  }
}

.block_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1310px;
}
.block_title_action_button {
  display: flex;
  width: 310px;
  align-items: center;
  justify-content: space-between;
}
.item_action,
.item_count {
  cursor: pointer;
}
.container {
  display: flex;
}
.button_back {
  flex: 1 1 100%;
  max-width: 100px;
  display: flex;
  align-items: baseline;
  line-height: 20px;
}
.button_backArr {
  transform: rotate(180deg);
  position: relative;
  margin-right: 18px;
}
.form__item {
  display: flex;
  flex-direction: column;
}
.form__title {
  margin-top: 60px;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.208px;
  color: #1c2f4c;
}
.backRed {
  background-color: #ff00002e;
}

.mt_20 {
  margin-top: 20px !important;
}

.reset_padding {
  padding: 42px 45px;

  h1 {
    padding: 0 50px;
  }
}