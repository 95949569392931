.headerNav__item {
	svg {
		visibility: hidden;
	}
	display: flex;
	margin-right: 50px;
	align-items: center;
	text-decoration: none;
	padding: 11px 20px;
  color: #214ba5;

	&_active {
    max-height: 49px;
    align-self: center;
		background: #f1f3ff;
		border-radius: 30px;
		svg {
			visibility: visible;
		}
	}
}
.headerNav__text {
	margin-left: 14px;
}
