@import '~assets/styles/variables';

.feedStatus {
  height: 50px;
  display: flex;
  align-content: center;
  border-top: 1px solid #f1f3ff;
  padding: 0 28px;
  margin-top: 0;
  cursor: pointer;

  &.active {
    background-color: $primary-bg;
  }

  &_wrapper {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.208px;
    color: #5b768b;
    text-align: center;
  }
  &__icon {
    width: 25px;
    height: 25px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .feedStatus__text {
    margin-left: 10px;
  }
}
