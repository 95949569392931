@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.wrapper {
  @include changeOpacityToVisibleAnimation;
  width: 100%;
  background-color: #fff;
  height: calc(100vh - (210px + 2.55vh));
  padding: 7.35vh;
  position: relative;
  overflow-y: auto;

  & > button:last-child {
    margin-top: 15px;
  }
}

.form {
  max-width: 530px;
  margin-top: 2.5vh;
}

.control {
  &__filterName {
    position: relative;
  }

  &__age,
  &__spendTime {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.select {
  margin-bottom: 16px;
}

.delete {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(200%, 100%);
  cursor: pointer;
}

.label {
  display: block;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.3px;
  line-height: 23px;
}

.actions {
  margin-top: 5.3vh;

  button:last-child {
    margin-top: 15px;
  }
}

.title {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
