@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.wrapper {
  height: 100%;
  padding: 2.55vh 1.5vw;
  overflow: auto scroll;
  display: flex;
}

.container {
  position: relative;
  width: 100%;
}

.alert {
  width: 520px;
  padding: 1px;
  margin: 0;
}

.tabNavItem {
  max-width: 190px;
  align-items: center;

  & p,
  h1 {
    color: #1c2f4c;
  }
}

.generateGroup {
  min-width: 285px;
}

.signupRequests {
  min-width: auto;
}

.disabled {
  pointer-events: none;
}
