.dashboard_item {
  width: 250px;
  height: 300px;
  background-color: #fff;
  border: 1px solid #f1f3ff;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
}

.item {
  &__ico {
    width: 37px;
    height: 37px;
    background: #f1f3ff;
    border-radius: 50%;
  }

  &__caption {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #5b768b;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    width: 100%;
    max-height: 40px;
    padding-inline: 3px;
    text-transform: capitalize;

    &_small {
      font-size: 16px;
    }
  }

  &__count {
    font-weight: 500;
    font-size: 50px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -5px;
    color: #214ba5;
    margin-bottom: -8px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    width: 100%;
    padding-inline: 3px;

    &_small {
      font-size: 35px;
    }
  }

  &__navButton {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #c6ccfe;
    border-radius: 50%;
    // margin-top: auto;
  }

  &__navButton,
  &__ico {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
  }
}
