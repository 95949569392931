.nav {
  position: absolute;
}

.terms {
  max-height: 65vh;
  padding: 30px 0;
  text-align: justify;
  word-break: break-word;

  .gray__text,
  .article__text,
  .red__text {
    margin-bottom: 30px;
    white-space: pre-line;
  }

  .gray__text {
    color: #858585;
  }

  .red__text {
    color: red;
  }

  .title {
    margin: 30px 0;
    font-size: 20px;
    color: #0e2dcc;
  }

  a {
    color: #01e0e0;

    &.blue {
      color: blue;
      text-decoration: none;
    }
  }

  ol {
    color: #858585;

    li {
      padding: 0 0 0 8px;

      &:not(:nth-child(1)) {
        margin-top: 0.5rem;
      }
    }
  }

  b {
    color: #858585;
  }

  hr {
    margin: 32px 0;
    color: #858585;
  }
}

.content {
  margin-top: 3vh;
  font-size: 16px;
  line-height: 130%;
}
