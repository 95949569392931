.nav {
	position: absolute;
}
.wrap2 {
	justify-content: center;
}

.article__text {
	font-size: 22px;
	line-height: 130%;
	color: #5b768b;
	margin-bottom: 50px;
}
