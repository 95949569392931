@import '~assets/styles/variables';

.title {
	padding-left: 19px;

	&__hide {
		display: none;
	}
}

.icon {
	display: flex;
	align-items: center;
	position: relative;

	&__counter {
		width: 23px;
		height: 23px;
		background: $secondary-main;
		border: 1px solid #fff;
		border-radius: 50%;
		font-weight: 500;
		font-size: 11px;
		line-height: 22px;
		color: #fff;
		position: absolute;
		text-align: center;
		overflow: hidden;
		top: -5px;
		left: 23px;
	}
}
