@import '~assets/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subtitle {
  color: $text-light;
  margin-left: 4px;
}
