@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.form {
  @include onboardingForm;

  &__link,
  &__link:visited {
    max-width: 160px;
    width: 100%;
    display: block;
    color: $text-light;
    align-self: start;
  }

  .button__container {
    max-width: 300px;
    width: 100%;
    margin-top: 3.5vh;
    display: flex;
    align-items: center;
  }

	.login_options {
		margin-top: 2.5vh;
		max-width: 94px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
  
  .loggedIn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
  
    span {
      font-size: 18px;
      line-height: 20px;
      color: $text-light;
    }
  
    button {
      margin-top: 2vh;
    }
  }
}
