.wrapper {
  padding-left: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
}

.total,
.invite {
  margin-left: 12px;
}

.invite {
  min-width: 40px;
}

.button_30 {
  min-width: 30px;
}

.tooltip {
  background-color: #E4E7FF !important;
  border: 1px solid  #C6CCFE !important;
  font-size: 12px;
  color: #1C2F4C !important;
  box-shadow: 0 0 20px 0 rgba(33, 75, 165, 0.15);
  border-radius: 8px;
  text-align: center;
}

.arrow {
  color: #C6CCFE !important;
}

