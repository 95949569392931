@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.time {
  &__wrapper {
    @include inputMain;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    column-gap: 16px;
    width: 100%;
  }

  &__value {
    width: 100%;
    text-align: center;
  }

  &__block_wrapper {
    width: 100%;
    display: flex;
  }

  &__divider {
    width: 1px;
    height: 100%;
    background-color: $primary-light;
  }
}
