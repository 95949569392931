.MuiSwitch-root.name {
	padding: 0;
	height: 22px;
	width: 42px;
	.MuiSwitch-switchBase {
		padding: 0;
	}
	.MuiSwitch-input {
		width: 400%;
	}
	.MuiSwitch-track {
		border-radius: 11px;
	}
	.MuiSwitch-colorSecondary.Mui-checked {
		color: #fff;
	}
	.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
		background-color: #214ba5;
	}
	.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
		opacity: 1;
	}
	.MuiSwitch-thumb {
		position: relative;
		top: 1px;
		left: 1px;
	}
}
