@import "~assets/styles/variables";

.switch {
  position: relative;
  width: 295px;
  height: 40px;
  overflow: hidden;
  display: flex;

  &__item {
    position: relative;
    width: 148px;
    border-radius: 30px;
    border: 1px solid $primary-light;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: -0.208px;
    color: $text-light;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;

    &_active {
      border: none;
      color: #fff;
      background: $primary-main;
      box-shadow: 2px 4px 4px rgba(33, 75, 165, 0.2);
      z-index: 2;
    }

    &:nth-child(1) {
      width: 178px;
    }

    &:nth-child(2) {
      left: -31px;
    }
  }
}

.disabled {
  background-color: #eee;
  color: #b1b7bb;
  pointer-events: none;
}
