.form__block {
	display: grid;
	max-width: 700px;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 75px 75px 200px 75px;
	gap: 0 24px;
	align-items: start;
	.textarea {
		grid-column: 1 / span 2;
		margin-bottom: 10px;
		position: relative;
		z-index: 4;
		textarea {
			height: 158px;
		}
	}
	button {
		grid-column: 2;
		justify-self: end;
	}
}

.captchaContainer {
	width: fit-content;
}