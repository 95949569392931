.fileStatusBar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.fileType {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.fileName {
  color: #4aa1f3;
  font-size: 10px;
  color: #1c2f4c;
  max-width: 100px;
  height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}

.fileError {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  color: #9aa9bb;
}

.fileErrorMessage {
  color: red;
}

.fileTypeLogo {
  width: 11px;
  height: 13px;
  background: url(~assets/image/document.svg) no-repeat center center;
  background-size: 100%;
}

.fileSize {
  font-size: 10px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #5b768b;
}

.fileRemove {
  flex: 0 0 auto;
  cursor: pointer;
}

.fileWrap {
  display: flex;
  align-items: center;
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  margin-right: 10px;
}

a.fileRemove {
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #1c2f4c;
    }
  }
}
