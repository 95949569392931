.filter {
  margin-top: 120px;
  min-width: 360px;
  max-width: 360px;
  max-height: 100vh;
  height: 100%;
  z-index: 10;;
  background: transparent;

  .form {
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}