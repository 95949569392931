@import '~assets/styles/variables';

.sidebar {
  max-width: 309px;
  width: 100%;
  height: calc(100vh - 100px);
  color: $text-main;
  font-size: 18px;
  box-shadow: 2px 2px 4px rgba(33, 75, 165, 0.1);
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 5;

  hr {
    width: 100%;
    height: 1px;
    background-color: $primary-light;
    border: none;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 64px);
    max-height: 633px;
    width: 100%;
    margin-top: 70px;
  }

  &__nav_small {
    max-height: 433px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: $text-main;
    font-size: 18px;
    padding: 0 33px;
    max-height: 70px;
    height: 100%;
    transition: background-color 0.2s;

    &:hover {
      background-color: $primary-bg;
    }

    &__disabled {
      pointer-events: none;
    }
  }

  &__hide {
    width: 104px;
    overflow: hidden;
  }
}

.logout {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 33px;
  max-height: 70px;
  height: 100%;
  cursor: pointer;

  &:hover {
    background-color: $primary-bg;
  }

  &__hide {
    & > span {
      display: none;
    }
  }
  & > span {
    padding-left: 19px;
  }
}

.active {
  background-color: $primary-bg;
}
