.popup {
  top: 0;
  height: 100vh;
  z-index: 999;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button {
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5vh;
    margin-top: 15px;
    height: 44px;
  }
}
