.popup {
  z-index: 100;
  position: absolute;
  width: 220px;
  background: #ffffff;
  border: 1px solid #f1f3ff;
  box-shadow: -2px -2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 8px;
  bottom: 0;
  right: 0;
  padding: 17px 17px 17px 17px;
  user-select: none;

  a {
    display: flex;
    text-decoration: none;
    margin-bottom: 23px;
  }

  .text {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #1c2f4c;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 99;
}

.crossButton {
  right: 240px;
  top: 38px;
  position: absolute;
  z-index: 101;

  &__wrapper {
    max-width: 324px;
    width: 100%;
  }
}
