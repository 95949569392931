.wrap {
  display: flex;
  justify-content: space-between;
  gap: 24px;

  .goalBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;

    .goalData {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 100%;

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 10px;
        color: #ffffff;
      }

      .progressLabel {
        font-size: 16px;
        font-weight: 800;
      }
    }

    .image {
      width: 24px;
      height: 24px;
    }
  }
}

.mobileWrap {
  gap: 16px;

  .mobileGoalsBlock {
    gap: 8px;
  }
}
