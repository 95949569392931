@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.main {
	width: 22px;
	height: 22px;
	outline: none;
	border-radius: 50%;
	transition: all 300ms;
	background-color: #fff;
	cursor: pointer;
	padding: 0;
	border: none;

	svg {
		width: 100%;
		height: 100%;
	}
}
