.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__field {
    margin-bottom: 16px;
    width: 100%;
  }

  .field_picker {
    align-self: flex-start;
  }

  .label {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }

  .labelAge {
    font-weight: 700;
    line-height: 21px;
    margin: 0 0 3px 10px;
  }
}
