@import '~assets/styles/variables';

.clock {
  display: flex;
  position: relative;

  &__info {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding-right: 15px;
    background: $primary-bg;
    transform: translateX(10px);

    .clock {
      &__icon {
        width: 23px;
        height: 23px;
        object-fit: contain;
      }

      &__text {
        color: $primary-main;
        font-size: 10px;
        line-height: 22px;
        margin-left: 7px;
      }
    }
  }

  &__disabled {
    .clock__info {
      opacity: 0.5;
    }
  }

  .checkbox {
    :global {
      .MuiSwitch-thumb {
        background-color: #fff;
      }

      .MuiSwitch-track {
        background-color: #f1f3ff;
        opacity: 1 !important;
      }
    }

    &__checked {
      :global {
        .MuiSwitch-track {
          background-color: #214ba5;
        }
      }
    }

    &__disabled {
      :global {
        .MuiSwitch-track {
          background-color: #f1f3ff !important;
          opacity: 0.5 !important;
        }
      }
    }
  }
}
