@import '~assets/styles/mixins';

.container {
	@include onboardingForm;
	@include changeOpacityToVisibleAnimation;

	.title__wrapper {
		display: flex;
		flex-direction: column;
		margin-bottom: 3.1vh;

		.title {
			line-height: 26px;
			text-transform: capitalize;
		}
		.sub_title {
			@include signUp-subtitle;
		}
	}
}

@media screen and (max-width: 1000px) {
  .container {
    max-width: 100%;
  }
}
