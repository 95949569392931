@import '~assets/styles/variables';

@mixin multilineThreeDots($lineClamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
}

@mixin lineThreeDots() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $container-bg;
}

.liveScreen {
  overflow: scroll;
  flex-grow: 1;
  max-width: 1920px;
  container-type: inline-size;

  &__wrapper {
    padding: 1.6vw 7.2vw;
    display: grid;
    grid-template-columns: repeat(3, 27vw);
    grid-template-rows: auto repeat(2, 17.7vw) 8.2vw;
    grid-template-areas:
      'hd hd hd'
      'th to mb'
      'rv rv mb'
      'ft ft mb';
    grid-gap: 2vw;
  }

  &__header {
    grid-area: hd;
    display: flex;
    align-items: center;
    padding-inline: 2vw;
    gap: 2vw;
    width: 100%;

    &-logo {
      height: 5.2vw;
      display: flex;
      align-items: center;
      gap: 1.6vw;

      &--image {
        height: 5.2vw;
        border-radius: 50%;
        object-fit: cover;
      }

      &--name {
        font-size: 2.5vw;
        font-weight: 500;
        letter-spacing: -0.05vw;
        text-decoration: none;
        color: $text-main;
      }
    }

    &__title {
      padding: 0.7vw;
      width: 100%;
      height: 8vw;
      border-radius: 20px;
      background-color: $white;

      &-wrapper {
        display: grid;
        overflow-y: auto;
        height: 100%;
        max-width: 58vw;
        word-break: break-word;
      }

      &-text {
        align-self: center;
        font-size: 1.4vw;
        color: $primary-main;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2.5vw 1.6vw;
    gap: 1vw;
    border-radius: 2vw;
    background: $white;

    &-title {
      justify-self: start;
      font-size: 2.5vw;
      font-weight: 500;
    }

    &-subtitle {
      width: max-content;
      padding-inline: 1.6vw;
      word-wrap: break-word;
      font-size: 1.6vw;
      @include multilineThreeDots(2);
      -webkit-line-clamp: 3;

      &_bold {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    &-count {
      margin-block: auto;
      max-width: 100%;
      font-size: 6vw;
      font-weight: 600;
      @include lineThreeDots();
    }

    &-totalHours {
      grid-area: th;

      .liveScreen__card-count {
        color: $secondary-main;
      }
    }

    &-totalOpportunities {
      grid-area: to;

      .liveScreen__card-count {
        color: $primary-main;
      }
    }

    &-recentlyVolunteer {
      grid-area: rv;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-opponame {
      max-width: 96%;
      @include multilineThreeDots(1);
      text-overflow: ellipsis;
      margin-left: 4px;
    }
  }

  &__volunteer {
    display: flex;
    padding-inline: 1.6vw;
    gap: 1.6vw;
    max-width: 100%;

    &__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 4.1vw;
      height: 4.1vw;
      max-width: unset;
      max-height: unset;
      border-radius: 50%;
      object-fit: cover;
      text-transform: capitalize;
      background: linear-gradient(113.8deg, #6e41fe -2.28%, #214ba5 100.88%);
      color: #fff;
      font-size: 2vh;
    }

    &__info {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.4vw;
    }

    &__name {
      font-size: 1.6vw;
      font-weight: 500;
      word-wrap: break-word;
      @include multilineThreeDots(2);
    }

    &__grade {
      font-size: 1.2vw;
    }

    &__oppo {
      width: auto !important;
      max-width: 100%;
      display: flex;

      &_padding {
        padding-inline: 7.2vw 1.6vw !important;
      }
    }
  }

  &__banner {
    grid-area: mb;

    &__media-file {
      width: 100%;
      height: 100%;
      border-radius: 1.6vw;
      object-fit: cover;
      transition: height 0.3s;
    }
  }

  &__footer {
    grid-area: ft;
    align-self: center;
    display: flex;
    align-items: center;
    height: 8vw;
    gap: 16px;
    padding: 0.7vw;
    border-radius: 20px;
    background: $white;

    &-logo {
      flex-shrink: 0;
      height: 4vw;
      object-fit: cover;
    }

    &__sutTitle {
      display: grid;
      overflow-y: auto;
      height: 100%;
      max-width: 58vw;
      word-break: break-word;

      &-text {
        align-self: center;
        width: 100%;
        font-size: 1.2vw;
        color: $primary-main;
        transition: all 0.3s;
      }
    }
  }
}

@media (max-width: 480px) {
  .liveScreen {
    &__wrapper {
      display: grid;
      grid-template-columns: calc(50% - 7.2vw) calc(50% - 7.2vw);
      grid-template-rows: 24vw repeat(2, 54vw) 24vw;
      grid-template-areas:
        'hd hd'
        'th to'
        'rv rv'
        'ft ft';
      grid-gap: 4vw;
      justify-content: center;
      padding: unset;
    }

    &__header {
      padding-inline: 2vw;
      gap: 2vw;

      &-logo {
        gap: 2vw;

        &--image {
          height: 12vw;
        }

        &--name {
          font-size: 4vw;
        }
      }

      &__title {
        padding: 2.4vw;
        height: 18vw;
        border-radius: 10px;

        &-text {
          font-size: 3.4vw;
        }
      }
    }

    &__card {
      padding: 2.5vw 1.6vw;
      &-title {
        font-size: 5vw;
        text-align: center;
      }

      &-subtitle {
        font-size: 3.6vw;
      }

      &-count {
        font-size: 12vw;
      }
    }

    &__volunteer {
      gap: 2.6vw;

      &__avatar {
        width: 10vw;
        height: 10vw;
      }
      &__name {
        font-size: 3.6vw;
      }
    }

    &__banner {
      display: none;
    }

    &__footer {
      padding: 2.7vw;
      border-radius: 10px;
      height: 18vw;

      &-logo {
        height: 8vw;
      }

      &__sutTitle {
        max-width: 70vw;
        &-text {
          font-size: 3.4vw;
        }
      }
    }
  }
}

.recentlyOppo {
  max-width: 100%;
  display: flex;
}
