@import '~assets/styles/variables';
@import '~assets/styles/mixins';

button.outlined {
  @include button_outlined;
}

.volunteer {
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 4px;
  }

  &.options_disable_mode {
    min-height: auto;

    .volunteer__info {
      &__photo {
        height: 40px;
        width: 40px;
      }

      &__photos {
        & > img {
          height: 40px;
          width: 40px;
        }

        span {
          left: 75px;
        }
      }
    }
  }

  &__info {
    min-height: 22px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    gap: 16px;
    margin-bottom: 4px;

    &_name {
      display: flex;
      flex-direction: column;
      max-width: 220px;
      overflow: hidden;

      & > span {
        @include multilineThreeDots(2);
        word-wrap: break-word;
      }
    }

    &__photo {
      display: block;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__photos {
      display: flex;
      align-items: center;
      position: relative;

      & > img {
        margin-right: 0;
        width: 26px;
        height: 26px;
        border: 1px solid #fff;
        object-fit: cover;

        &:not(:nth-child(1)) {
          position: relative;
          margin-left: -13px;
        }
      }

      &.two_photos {
        & > img {
          position: relative;

          &:not(:nth-child(1)) {
            left: 4px;
          }
        }
      }
    }

    &__counter {
      position: relative;
      margin-left: 12px;
      font-size: 12px;
      line-height: 13px;
      color: #a1b5ca;
    }
  }

  &__options {
    max-width: 130px;
    width: 100%;
    display: flex;
    justify-content: unset;
    margin-top: 4px;

    &_pending {
      height: 20px;
      width: 20px;
      position: relative;
      left: -8px;
      top: -4px;
      background: $secondary-main;
      border: 1px solid #fff;
      border-radius: 50%;
      color: #fff;
      font-weight: 500;
      font-size: 8px;
      line-height: 20px;
      text-align: center;
      cursor: default;
    }

    & > svg {
      display: block;
      margin-right: 8px;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}

.volunteersLimit {
  min-width: 100px;
}
