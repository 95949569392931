.nav {
  position: absolute;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.wrap2 {
  justify-content: center;
}

.content {
  margin-top: 3vh;
}

.article__text {
  font-size: 22px;
  line-height: 130%;
  color: #5b768b;
  margin-bottom: 50px;
}

.donationOptions {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.popup {
  top: 0;
  padding-top: 6vh;
  & > div {
    height: calc(100vh - 90px - 7.9vh) !important;
    margin: 80px auto 20px !important;
  }
}
