.content {
  max-width: 900px;
  width: 100%;

  &__header {
    max-width: 900px;
    width: 100%;
    margin-bottom: 2.6vh;
    display: flex;
    flex-direction: column;
    z-index: 2;

    &__chesed_wrapper {
      display: flex;
      margin-top: 12px;
    }

    &__chesed {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.208px;
      max-width: 540px;
      font-weight: 600;
      margin-right: 6px;

      &_name {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.208px;
        max-width: 540px;
      }
    }

    button {
      margin-left: auto;
    }
  }
}

.volunteersLimit {
  display: flex;
  align-items: center;
  &__title {
    margin-right: 6px;
    font-weight: 700;
    font-size: 14px;
  }
  &__wrapper {
    max-width: '100px';
  }
}
