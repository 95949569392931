@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.wrapper {
  height: 100%;
  padding: 2.55vh 1.5vw;
  overflow: hidden scroll;
  display: flex;
  gap: 1.5vw;
}

.container {
  position: relative;
  width: 100%;
}
