.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__field {
    margin-bottom: 8px;
    width: 100%;
  }

  .pre__button {
    margin-bottom: 14px;
  }

  &__opportunitie_address {
    display: flex;
    margin-bottom: 2.3vh;
    width: 100%;

    button {
      width: 48%;
    }
    button:first-child {
      margin-right: 12px;
    }
  }

  .field_picker {
    align-self: flex-start;
  }

  .label {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 10px;
    margin-right: 25px;
    align-self: flex-start;
  }

  .label_for_button {
    cursor: pointer;

    input {
      display: none;
    }
  }

  .form__location_type {
    gap: 12px;
    .label_for_button {
      width: 100%;
      button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .form__oppo_type {
    gap: 12px;
    justify-content: space-between;
    .label_for_button {
      width: 100%;
      button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
