@import '~assets/styles/variables';

$org-settings-height: calc(100vh - 100px);
$org-settings-content-height: calc($org-settings-height - 126.4px - 120px);

//26,4 + 40 + 60

.organisationSettings {
  display: flex;
  flex-direction: column;
  padding: 60px;
  height: $org-settings-height;

  &__title {
    margin-bottom: 40px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.4px;
    color: $text-main;
  }

  &__tabNav {
    top: 126px;
    overflow-x: unset;
  }

  &__tabItem {
    display: ruby-text;
    padding: 20px 24px;
    min-width: 190px !important;
    max-width: 240px;
    text-align: center;

    & > span {
      padding: unset !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__content {
    padding: 28px 34px 0 34px;
    height: $org-settings-content-height;
    background-color: $white;
    overflow-y: auto;
  }

  &__tab {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 36px;

    &__title {
      margin-bottom: 24px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $text-light;
    }

    &__form {
      display: flex;
      flex-direction: column;
      height: 100%;

      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      &_item {
        display: flex;
        flex-direction: column;
        width: 33.3%;
        gap: 4px;

        &__label {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $text-main;
        }
      }

      &__LogYourChased {
        gap: 16px;

        &_label {
          margin-bottom: 18px;
          font-size: 18px;
          font-weight: 700;
          line-height: 23.58px;
          font-style: normal;
          letter-spacing: -0.18px;
        }

        &_checkbox {
          &__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $text-main;

            span:after {
              top: -4px !important;
            }
          }
        }
      }
    }

    &__organizationInfo {
      width: 66.6%;

      form button {
        margin-bottom: 28px;
      }
    }
  }
}

.button {
  &__wrapper {
    display: flex;
    justify-content: center;
    gap: 60px;
    padding-bottom: 28px;
  }

  &__LogYourChesed__wrapper {
    display: flex;
    justify-content: end;
    gap: 60px;
    padding-bottom: 28px;
  }
}