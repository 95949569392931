.avatar {
  display: flex;
  align-items: center;
  width: 60px;
  position: relative;
  //max-width: 60px;
  //width: 100%;

  & > img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
  }
}
