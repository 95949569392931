.wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.label {
  font-size: 14px;
}

.container {
  width: fit-content;
  height: 32px;
  margin: 0;
  min-height: auto;
}

.endDate input {
  height: 32px;
}
