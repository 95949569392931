@import '~assets/styles/mixins.scss';

@import '~assets/styles/mixins';

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
}

.subtitle {
	display: block;
	font-size: 18px;
	line-height: 23px;
	letter-spacing: -0.01em;
	text-align: center;
	margin-bottom: 4vh;
}

.link_text {
	text-align: center;

	img {
		max-width: 200px;
		width: 100%;
	}
}
