@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.select {
  width: 100%;
  position: relative;
  font-size: 18px;
  user-select: none;
  height: auto;
  min-height: unset;

  &__head {
    width: 100%;
    height: 40px;
    padding-inline: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border: 1px solid $primary-light;
    border-radius: 30px;
    cursor: pointer;

    &_org {
      background: $white;
    }

    &_disabled {
      background: $disabled;
      color: $disabled;
      cursor: default;
    }

    &_filterDisabled {
      cursor: default;
    }
  }

  &__title {
    color: $text-light;
  }

  &__arrow {
    min-width: 11px;
    transform: rotate(180deg);

    & > path {
      fill: currentColor;
      color: $primary-main;
    }
  }

  &__options {
    max-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $primary-bg;
    border-top: 0;
    border-radius: 0 0 20px 20px;
    position: absolute;
    z-index: $z-select-options;
    top: 40px;
    overflow: auto;
  }

  &_selected {
    .select__title {
      color: $text-main;
    }
  }

  &_active {
    .select__head {
      border: 1px solid $secondary-main;
      border-radius: 20px 20px 0 0;
      background-color: $secondary-main;
    }

    .select__title {
      color: $white;

      .select__orgTitle {
        margin-left: 20px;
      }
    }

    .select__arrow {
      transform: unset;

      & > path {
        color: $white;
      }
    }
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.option {
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-inline: 24px;
  border-bottom: 1px solid $primary-bg;
  background-color: $white;
  cursor: pointer;

  &:last-child {
    border-bottom: unset;
  }

  &:hover {
    background-color: $primary-bg;
  }

  &__image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__title {
    flex: 1;
  }

  &__helperText {
    max-width: calc(64% - 100px);
  }
}
