@import "~assets/styles/mixins";

.container {
  @include orgPage;
}

.block_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 46px;
  width: 100%;
}
.block_title_action_button {
  display: flex;
  max-width: 310px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.item_action,
.item_count {
  cursor: pointer;
}

.warning {
  background-color: #fff;
  border: 1px solid #f1f3ff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 20vh;
  padding: 40px 20px 0;
  margin-bottom: 30px;

  &__title {
    margin-bottom: 20px;
  }

  &__content {
    font-size: 16px;
  }

  &__link {
    color: #214ba5;
    cursor: pointer;
    text-decoration: underline;
  }
}
