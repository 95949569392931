.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  .wrapper {
    justify-content: center;
    gap: 20px;
  }
}
