@import '~assets/styles/variables';

.video__wrapper {
  width: 100%;
  height: 100%;
  background-color: $primary-bg;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .player {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.video__actionButton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  width: 82px;
  height: 82px;
  background: rgba($primary-bg, 0.7);
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 7px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 9px;
    border-top-width: 9px;
    border-left: 14px solid #fff;
    cursor: pointer;
  }
}

.smaller {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  background: rgba($primary-bg, 0.7);
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 7px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 9px;
    border-top-width: 9px;
    border-left: 14px solid #fff;
    cursor: pointer;
  }
}

