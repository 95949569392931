.progressBarContainer {
  width: 100%;
  height: 8px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;

  .progressFill {
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 186, 51, 1) 0%, rgba(255, 161, 51, 1) 40%, rgba(255, 139, 3, 1) 100%);
    transition: width 0.3s ease-in-out;
  }
}
