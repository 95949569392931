@import '~assets/styles/mixins';

.team__user {
	width: 377px;
	height: 106px;
	padding: 25px 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #c6ccfe;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center !important;
	gap: 20px;
}
.user__info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 10px;
}
.user__logo {
	margin-right: 25px;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	overflow: hidden;
	background-color: #cecece;
	object-fit: cover;
	margin-bottom: 0 !important;
}
.user__name {
  @include threeDots();
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
}
.organizationUser_FullName {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
}
.user__skill {
	display: flex;
	align-items: center;
	gap: 5px;
}
.skill__grade {
	font-size: 12px;
	line-height: 130%;
}
.skill__pointCount {
	font-size: 12px;
	line-height: 130%;
	color: #5b768b;
}
.ico {
	width: 18px;
	height: 18px;
	background: url(~assets/image/rookie.svg);
	background-size: cover;
}
.roles {
	margin-left: 10px;
	text-transform: capitalize;
}
.organisationUser_description {
	display: flex;
	align-items: center;
}
