.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px;
  height: 100%;
}

.close {
  cursor: pointer;
}
