@import '~assets/styles/variables';
@import '~assets/styles/mixins';
@import '~assets/styles/keyframes';

.wrapper {
  @include changeOpacityToVisibleAnimation;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //height: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba($primary-bg, 0.8);
  z-index: 100;
  overflow-y: auto;
  padding: 0 2vw;

  &.contentCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.padding_disabled {
    padding: 0;
  }

  &.fixed {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
  }

  &.broadcast {
    background-color: rgb(151, 151, 151, 0.6);
  }

  .button_cross {
    right: 30px;
    top: 30px;
    position: absolute;
    z-index: 11;
  }

  &.close {
    @include changeOpacityToUnvisibleAnimation;
  }

  &.photo_container {
    height: 100vh;
    top: 0;
    z-index: 105;
  }
  &.photo_container_updating {
    height: 100vh;
  }

  .content__container {
    max-width: 912px;
    width: 100%;
    height: calc(100vh - 100px - 64px);
    background-color: #fff;
    opacity: 1;
    box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
    border-radius: 10px;
    margin: 32px 10%;
    padding-top: 7.9vh;
    padding-bottom: 5vh;
    padding-left: 4.95vw;
    padding-right: 4.95vw;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &.photo {
      height: auto;
      width: auto;
    }

    &.broadcast {
      height: auto;
      margin-top: 0;
      padding: 0;
    }

    &.marginTop_disabled {
      margin-top: 0;
    }

    &.contentFullWidth {
      max-width: 1100px;
      margin-top: 6.5vh;
      padding-left: 1.95vw;
      padding-right: 1.95vw;
      align-items: center;
    }
  }

  &.padding_disabled > .content__container {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      min-height: auto;
    }
  }

  &.contentHeightAuto > .content__container {
    height: auto;
  }

  &.contentWidthAuto > .content__container {
    width: auto;
  }
}
