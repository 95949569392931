@import '~assets/styles/mixins';

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;

  .dropDown__Block {
    @include changeOpacityToVisibleAnimation;
    position: absolute;
    width: 242px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #fff;
    right: 30px;
    top: 100px;
    box-shadow: 2px 2px 4px rgba(28, 47, 76, 0.1);
    border-radius: 8px;

    &::before {
      content: '';
      width: 27px;
      height: 27px;
      background-color: #f1f3ff;
      transform: rotate(45deg);
      position: absolute;
      top: -14px;
      right: 40px;
      z-index: -1;
    }
  }
  .userDescription__block {
    background-color: #f1f3ff;
  }
  .userMenu__block {
    nav.menu {
      margin: 0;
      padding: 0;
    }
  }
  .df_column {
    flex-direction: column;
    align-items: flex-start !important;
    height: auto !important;
    width: 100% !important;
    border-bottom: none;
    img {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
  .version {
    display: block;
    margin-left: 18px;
    margin-bottom: 18px;
    margin-top: 50px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.208px;
    color: #c6ccfe;
  }
}
