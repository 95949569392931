@import '~assets/styles/mixins';
@import '~assets/styles/variables';
@import '~assets/styles/keyframes';

.phone {
  position: relative;
  height: 40px;
}
.PhoneInputWrapper {
  position: relative;
}
.PhonePlaceholderUS {
  position: absolute;
  z-index: 1;
  top: 9px;
  left: 80px;
  font-size: 18px !important;
  line-height: normal !important;
  color: #5b768b;
}

.phone__input {
  @include inputMain;
  padding: 8px 38px 8px 24px !important;
  width: 100% !important;
  outline: none !important;
  border: 1px solid #c6ccfe !important;
  border-radius: 30px !important;
  font-size: 18px !important;
  line-height: normal !important;
  transition: all 200ms !important;
  padding-left: 55px !important;
  position: relative;

  &:focus {
    border-color: #ffa133 !important;
    transition: border-color 200ms !important;
    box-shadow: none !important;
  }

  &_edit {
    @include inputEdit;
    animation: opacityToVisible 0.2s ease;
    opacity: 1;
  }
}

.phone__input.open {
  border-color: #ffa133 !important;
  transition: border-color 200ms !important;
  box-shadow: none !important;
  border-bottom-left-radius: 0px !important;
}

.flag__button {
  border-radius: 50% !important;
  position: relative !important;
  height: 39px !important;
  transform: translateY(-100%) !important;
  width: 52px !important;
  z-index: 7 !important;
}

.flag__dropdown {
  margin: 0 !important;
  box-shadow: none !important;
  background-color: #fff !important;
  border: 1px solid #c6ccfe !important;
  max-height: 220px;
  border-radius: 0px !important;
  border-bottom-left-radius: 20px !important;
  width: 300px !important;
  font-size: 18px !important;
  line-height: normal !important;
  left: 15px;

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar {
    width: 7px !important;
  }

  li {
    background-color: #fff !important;

    &:hover {
      background-color: $primary-bg !important;
    }
  }
}
