@import '~assets/styles/mixins';

.form {
  @include changeOpacityToVisibleAnimation;
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.multiple_fields_wrapper {
  display: flex;
  justify-content: space-between;
}

.half_field_wrapper {
  position: relative;
  max-width: 345px;
  width: 345px;

  &_warning {
    &_absolute {
      position: absolute;
    }

    top: 80px;
    display: flex;

    & svg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;

      & path {
        fill: #214ba5;
      }
    }

    &_message {
      margin-left: 6px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.2px;
    }
  }
}

.mt_submit_button {
  margin-top: 30px;
}

.mb_4 {
  margin-bottom: 4px;
}

.mb_16 {
  margin-bottom: 16px;
}

.email {
  display: flex;
  flex-direction: column;
  width: 100%;

  //span {
  //  font-size: 12px;
  //  line-height: 18px;
  //  letter-spacing: -0.2px;
  //  color: #d00074;
  //}
}

.mb_0 {
  margin-bottom: 0;
}
