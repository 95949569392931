.form {
  margin-top: -15px;

  &__school {
    .form__button {
      margin-top: 24px;
    }

    & > :nth-child(2n) {
      margin-bottom: unset;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
}
