.date {
  &__details {
    margin-top: 27px;
    border-top: 1px solid #f1f3ff;
    border-bottom: 1px solid #f1f3ff;
    padding: 17px 0 22px;
  }

  &__details {
    &_where,
    &_when {
      display: flex;
    }
  }

  &__ico {
    margin-right: 16px;

    svg {
      width: 11px;
      height: 11px;
    }
  }
}
