@import 'src/assets/styles/variables';

.tabs {
  display: flex;
  gap: 8px;

  &__tab {
    height: 40px;
    padding-inline: 28px;
    min-width: 132px;
    border: 1px solid $primary-light;
    border-radius: 30px;
    background: #fff;
    color: $text-light;
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.208px;
    cursor: pointer;
    transition: all 0.2s ease;

    &_active {
      border: 1px solid $primary-main;
      background: $primary-main;
      color: #fff;
      box-shadow: 2px 4px 4px rgba(33, 75, 165, 0.2);
    }
  }
}
