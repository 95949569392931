@import '~assets/styles/variables';

.container {
  height: 75px;
  width: 100%;
  display: flex;
  &.big {
    height: 150px;
  }
  .input__wrapper {
    width: 69px;
    height: 75px;
    border: 1px solid $primary-light;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 20px;
    &.big {
      width: 150px;
      height: 150px;
    }

    input {
      display: none;
    }

    .upload {
      &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .upload__options {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .option__item {
      cursor: pointer;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: $primary-main;

      &_upload {
        display: inline-flex;

        span {
          margin-left: 4px;
        }
      }
    }
  }
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
