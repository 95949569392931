@import '~assets/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;

  .contact,
  .past__oppo {
    max-width: 95px;
    min-width: 95px;
    justify-self: center;

    &_button {
      @include button_outlined;
      @include lineThreeDots;
      max-width: 95px;
      min-width: 95px;
    }

    button {
      width: 100%;
    }
  }
}
