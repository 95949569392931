.userMenu__item {
  display: flex;
  text-decoration: none;
  margin-top: 0;
  padding: 18px 24px;
  list-style-type: none;
  border-bottom: 1px solid #c6ccfe;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid #c6ccfe;
  }

  &:hover {
    background-color: #f1f3ff;
  }

  .userMenu__ico {
    width: 33px;
    height: 33px;
    background: #f1f3ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 27px;
    overflow: hidden;
    flex-shrink: 0;
    padding: 20px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .userMenu__text {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.208px;
    color: #1c2f4c;
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}
