@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.form {
  @include onboardingForm;
  position: relative;

  &__link,
  &__link:visited {
    max-width: 160px;
    width: 100%;
    display: block;
    color: $text-light;
    align-self: start;
  }

  &__info {
    position: absolute;
    top: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;

    & svg {
      width: 18px;
      height: 18px;

      & path {
        fill: #214ba5;
      }
    }
  }

  .button__container {
    max-width: 300px;
    width: 100%;
    margin-top: 2.5vh;
    display: flex;
    align-items: center;
  }
}

.create_account {
  margin-top: 2vh;
}

.login_options {
  margin-top: 2.5vh;
  max-width: 94px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
