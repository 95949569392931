.badges {
	display: flex;
	align-items: center;
	margin-bottom: 4px;

	.badge {
		&_verified {
			height: 16px;
		}
	}
}
