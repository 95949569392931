@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	max-width: 335px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.title {
		margin-top: 3.3vh;
		margin-bottom: 2.1vh;
		text-align: center;
	}

	.form {
		width: 100%;
	}
}
