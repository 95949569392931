.content {
	max-width: 350px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 4.9vh;
	text-align: center;

	&__message {
		margin-top: 3vh;
    white-space: pre-line;
	}

	button {
		margin-top: 4vh;
		margin-bottom: 4vh;
	}
}
