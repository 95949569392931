@import "~assets/styles/mixins";

.marketing {
  padding: 60px;

  &__title {
    margin-bottom: 40px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    color: #1C2F4C;
  }

  &__tabs {
    height: 62px;
    gap: 8px;

    &_item {
      min-width: 190px !important;
      padding: 20px 24px;
      margin: unset;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #5B768B;

      & > span {
        width: unset !important;
        height: unset !important;
        padding: unset !important;
      }

      & > div:last-child {
        display: flex;
        padding: 2px 6px;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        min-width: unset;
        height: unset;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
  }

  &__layout {
    display: flex;
    flex-direction: column;
    padding: 28px 34px;
    background-color: white;
  }
}

.container {
  @include orgPage;
}

.block_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 46px;
  max-width: 1310px;
}

.block_title_action_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 36px;
}

.item_action,
.item_count {
  cursor: pointer;
}

.popupTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.208px;
  color: #1c2f4c;
  margin-bottom: 30px;
}
