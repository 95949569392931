@import '~assets/styles/mixins';

.channel-preview__container {
  height: 77px;
  /* padding-bottom: 8px; */
  padding-left: 18px;
  padding-right: 18px;
  /* border-radius: 8px; */
  border-top: 1px solid #f1f3ff;
  border-bottom: 1px solid #f1f3ff;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 8px; */

  &:hover {
    background: #e4e7ff;
    /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07); */
    transition: background 0.1s ease-in-out;
  }

  &.selected {
    background: #e4e7ff;
    /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07); */
    transition: background 0.1s ease-in-out;
  }

  & .str-chat__avatar {
    margin-right: 0;
  }

  & + & {
    border-top: none;
  }
}

.channel-preview__content {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    column-gap: 12px;
    margin-left: 8px;
    width: 100%;
  }

  &-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    margin-bottom: 4px;
    gap: 4px;
  }

  &-name {
    font-family: Helvetica Neue, sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    margin: 0;
    max-width: 158px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-message {
    font-family: Helvetica Neue, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    color: #858688;
    max-width: 172px;
    text-align: start !important;
    word-wrap: break-word;
    @include multilineThreeDots(2);
  }

  &-bottom {
    position: absolute;
    right: 0;
    top: 0;
    width: 96px;
  }
}

.channel-preview {
  &__avatars {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    border-radius: 20px;
    overflow: hidden;

    &.two {
      div {
        &:first-child {
          position: relative;
          right: 10px;
        }

        &:nth-child(2) {
          position: relative;
          right: 30px;
        }
      }
    }

    &.two,
    &.three {
      span {
        width: 20px;
        overflow: hidden;
      }
    }
  }

  &__unread-message-count {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #ffffff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffa133;
    margin-top: 10px;
    font-size: 12px !important;
  }
}

.str-chat.dark {
  & .channel-preview {
    &__container {
      &:hover {
        background: #2c2c30;
      }

      &.selected {
        background: #2c2c30;
      }
    }

    &__content {
      &-name {
        color: #ffffff;
      }

      &-time {
        font-family: Helvetica Neue, sans-serif;
        font-size: 11px;
        color: #858688;
        margin: 0;
      }
    }
  }
}
