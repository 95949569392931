.main {
	position: absolute;
	top: 38px;
	right: 38px;
	font-weight: 500;
	font-size: 18px;

	&.withTitle {
		position: static;
		width: max-content;
	}
}
