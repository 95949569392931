@import '~assets/styles/mixins';

.team__user {
	width: 377px;
	height: 106px;
	padding: 25px 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #c6ccfe;

	// &:last-child {
	//   border-bottom: none;
	// }
}
.user__logo {
	margin-right: 25px;
	width: 56px;
	height: 56px;
	object-fit: cover;
	border-radius: 50%;
	overflow: hidden;
	background-color: #cecece;
}
.user__name {
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	color: #1c2f4c;
	@include threeDots();
}
.user__skill {
	display: flex;
	align-items: center;
	gap: 5px;
}
.skill__grade {
	font-size: 12px;
	line-height: 130%;
	color: #1c2f4c;
}
.skill__pointCount {
	font-size: 12px;
	line-height: 130%;
	color: #5b768b;
	margin: 0 0 0 35px;
}

.volunteerSpendTime {
	color: #5b768b;
	margin: 3px 0 0 35px;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: -0.3px;
}
