.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .image {
    width: 24px;
    height: 24px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 13.2px;
    color: #ffffff;
    margin: 0 0;

    .coinsAmount {
      font-size: 24px;
      font-weight: 800;
      line-height: 26.4px;
      margin-right: 12px;
    }
  }
}

.mobileWrap {
  p {
    .mobileCoins {
      margin-right: 8px;
    }
  }
}
