@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.input_file {
  width: 100%;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }

  .upload {
    @include changeOpacityToVisibleAnimation;
    width: 100px;
    height: 100px;
    background: no-repeat center center url('~assets/image/inputs/file-loader.svg');
    background-size: contain;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    margin-right: 1.3vw;
    outline: none;

    &::after {
      content: '';
      display: block;
      width: 62px;
      height: 62px;
      background: no-repeat center center url('~assets/image/inputs/file-loader-part.svg');
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__error {
      width: max-content;
    }

    &__button {
      display: inline-block;
      margin-bottom: unset;
    }
  }

  .loader {
    @include changeOpacityToVisibleAnimation;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.3vw;
  }

  .input_wrapper {
    max-width: 150px;
    width: 100%;
    position: relative;

    div {
      line-height: 18px;

      label {
        margin: 0;
        margin-left: 5px;
        color: $primary-main;
        cursor: pointer;
      }
    }

    input {
      display: none;
    }
  }
}
