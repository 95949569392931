.str-chat.str-chat-channel-list {
	/* height: fit-content; */
	height: 100%;
}
.messaging__channel-list {
	/* padding: 20px 10px 10px 10px; */
	height: 100%;
	overflow-y: auto;
	width: 375px;
	/* border-right: 1px solid #C6CCFE; */
	position: relative;
}
.messaging.str-chat .str-chat__list {
	background: #f1f3ff;
}
.str-chat-channel.messaging .str-chat__main-panel {
	padding: 0;
}
#mobile-channel-list {
	/* flex: 1 0 auto; */
	/* overflow-y: auto; */
	border-right: 1px solid #c6ccfe;
}

@media screen and (max-width: 640px) {
	.messaging__channel-list {
		width: unset;
	}
}

.messaging__channel-list__header {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 25px 32px;
	background-color: #fff;
	z-index: 1;
	/* margin-bottom: 20px; */
}
.messaging__channel-list__title {
	align-self: flex-start;
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 24px;
	line-height: 26px;
	color: #1c2f4c;
}

.messaging__channel-list__message {
	margin-left: 30px;
	margin-top: 30px;
	color: #000;
}

.str-chat.dark .messaging__channel-list__message {
	color: #fff;
}

.messaging__channel-list__header__name {
	display: flex;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #000;
}

.str-chat.dark .messaging__channel-list__header__name {
	color: white;
}

.messaging__channel-list__header__button {
	width: 40px;
	height: 40px;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	cursor: pointer;
	background: #fafafa;
	border-radius: 20px;
	margin-right: 20px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.messaging__channel-list__header__button svg path {
	fill: #005fff;
}

.str-chat.dark .messaging__channel-list__header__button {
	background: #3e3e41;
	box-shadow: none;
}

.str-chat.dark .messaging__channel-list__header__button svg path {
	fill: #e9e9ea;
}

.str-chat__load-more-button {
	display: block;
}

.messaging__channel-list__header__button:focus {
	border: none;
	outline: none;
}

/* Skeleton Loader */

.skeleton-loader__list {
	position: absolute;
	width: 260px;
	height: 100%;
	float: left;
	margin-top: 0;
	padding: 0;
	list-style: none;
}

@media screen and (max-width: 640px) {
	.skeleton-loader__list {
		width: unset;
	}
}

.skeleton-loader__list li {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}

.skeleton-loader__avatar,
.skeleton-loader__text {
	display: inline-block;
}

.skeleton-loader__avatar {
	float: left;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	border-radius: 100px;
}

.skeleton-loader__text div {
	width: 200px;
	height: 12px;
	border-radius: 20px;
}

.skeleton-loader__text div:nth-child(2) {
	height: 6px;
	margin-top: 8px;
}

.skeleton-loader__avatar,
.skeleton-loader__text div {
	animation: placeHolderShimmer 1.5s linear infinite;
	background: rgba(255, 255, 255, 0.2);
	background-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) 5%,
		rgba(0, 0, 0, 0.2) 25%,
		rgba(0, 0, 0, 0.25) 50%,
		rgba(0, 0, 0, 0.2) 75%,
		rgba(0, 0, 0, 0) 95%,
		rgba(0, 0, 0, 0) 100%
	);
	background-repeat: no-repeat;
	background-size: 400px 100%;
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -400px 0;
	}
	100% {
		background-position: 400px 0;
	}
}

/* .messaging__channel-list__contentWrapper {
	height: 100%;
	overflow-y: auto;
} */
