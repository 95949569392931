@import "~assets/styles/variables";

.main {
  background-color: transparent;
  border: 1px solid $primary-light;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  transition: all 0.2s ease;

  &_blue {
    border: none;
    border-radius: 50%;
    background-color: #c6ccfe;
    color: $text-light;
  }

  .placeholder {
    font-size: 13px;
    font-weight: normal;

    &_blue {
      font-size: 16px;
    }
  }

  &.checked {
    border-color: $secondary-main;
    background-color: $secondary-main;
    color: #fff;

    &_blue {
      background-color: #214ba5;
    }
  }
}
