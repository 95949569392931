.popup {
  background-color: #fff;
  box-shadow: 2px 2px 4px 4px rgba(33, 75, 165, 0.2);
  border-radius: 10px;
  position: absolute;
  z-index: 10;
  padding: 14px;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);
  top: 10px;

  &__content {
    width: 245px;
    align-items: center;
    justify-content: space-between;

    &__includeOpportunities {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: -102px;

      &__text {
        font-weight: 700;
        font-size: 15px;
        line-height: 21px;
      }

      &__checkboxContainer {
        width: 16px;
        height: 16px;
        top: -1px;
        position: relative;
      }
    }

    &__includeDateRange {
      margin-top: unset;
    }
  }
}

.hide {
  display: none;
}

.close__button {
  position: absolute;
  top: 11px;
  right: 11px;
  background: transparent;
}

.checkbox {
  margin: 0;
  padding: 0;
  position: unset;
}
