@import '~assets/styles/variables';

.delete_hashtag__modal {
  & > div:first-child {
    background-color: rgba(241, 243, 255, 0.8);
  }
  & > div:nth-child(3) > div {
    border-radius: 10px;
  }

  .content {
    width: 380px;
    height: 230px;
    border-radius: 12px;
    padding: 12px;
    background: $gradients-orange;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      margin-bottom: 10px;
      font-size: 20px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__button__wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-top: 30px;
    }

    &__button_confirm {
      width: 100px;
      height: 34px;
    }

    &__button__cancel {
      width: 100px;
      height: 34px;
    }

    &__close__button {
      position: absolute;
      top: 11px;
      right: 11px;
      background: transparent;
    }
  }
}
