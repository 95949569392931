@import '~assets/styles/mixins';

.main {
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.208px;
  white-space: nowrap;
  text-align: left;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: default;
}

.sortTriangles {
  cursor: pointer;
  margin-left: 10px;

  &_default {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    opacity: 0.4;
  }
}

.image {
  display: inline-block;
  transition: transform 0.2s;

  &.ascDir {
    transform: rotate(180deg);
  }
}

.gender {
  text-align: start;
  text-transform: capitalize;
}

.forms {
  width: 100%;
}

.time {
  max-width: 80px;
}

.coordinator {
  max-width: 155px;
  width: 100%;
}

.volunteers {
  max-width: 165px;
  width: 100%;
}

.options {
  max-width: 95px;
}

.volunteers_actions {
  max-width: 135px;
  min-width: 50px;
}

.checkbox {
  width: 25px;
}

.position {
  max-width: 70px;
  width: 100%;
}

.role {
  max-width: 100px;
  width: 100%;
}

.past_opportunities {
  width: 95px;
}

.completedHours {
  white-space: pre;
}

.clickable {
  cursor: pointer;
}

.cell {
  &__container {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__arrow {
    transform: rotate(90deg);
    margin-left: 10px;
    cursor: pointer;
  }
}

.option {
  &__container {
    position: relative;
    padding: 20px;
  }

  &__cross {
    right: 20px;
    top: 12px;
    position: absolute;
    z-index: 11;
  }
}

.group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  color: #5b768b;

  :global {
    .MuiRadio-colorSecondary.Mui-checked {
      color: #214ba5;
    }
  }
}

.schoolGrade {
  max-width: 30%;
  width: 100%;
  white-space: pre-wrap;
}

.date {
  justify-content: center;
}

.yearTimeSpend,
.amountOppo,
.total {
  width: auto;
  white-space: pre;
}

.info__icon {
  margin-left: 4px;

  width: 18px;
  height: 18px;

  cursor: pointer;

  path {
    fill: #214ba5;
  }
}