.adminCell {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__add_icon {
    cursor: pointer;
    margin-left: 6px;
  }
}
