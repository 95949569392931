@import '~assets/styles/variables';

.hashtag__wrapper {
  display: flex;
  margin-bottom: 20px;
}

.hashtag_label__admin {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: $text-main;
}

.hashtag_label__volunteer {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.3px;
  line-height: 23px;
  color: $text-main;
}

.hashtag_label__admin, .hashtag_label__volunteer {
  margin: 0;
}

.hashtag_label__required {
  color: #D00074;
}

.error {
  margin-bottom: 16px;
}