@import '~assets/styles/variables';

.text {
  color: $text-main;
  font-size: 14px;
  &__share {
    margin-left: 2px;
    color: $text-main;
    font-size: 14px;
  }

  &__view {
    margin-left: 0;
    color: $text-main;
    font-size: 14px;
  }
}
.icon {
  margin-right: 6px;
  &__share {
    position: relative;
    left: -2px;
    margin-right: 2px;
  }
}

.share {
  position: static;
}

.more__button {
  &:hover {
    background-color: unset !important;
  }
}

.menu__item {
  &:hover {
    background-color: $primary-bg !important;
  }
}
