.container {
  width: 100%;
  padding: 27px 34px;
  margin-right: 2.4vw;
  background-color: #fff;
  height: min-content;

  .table {
    margin-top: 22px;

    .tableItem__options {
      max-width: 97px;
      width: 100%;
      height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }

    &__main {
      overflow: clip;
    }

    &__header {
      overflow: auto hidden;
      position: sticky;
      top: -2.55vh;
      height: 60px;
      z-index: 7;
    }

    &__body {
      overflow: auto hidden;
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    .fullname {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      & > div {
        max-width: none;
      }
    }

    .loader {
      width: min-content;
      margin: 0 auto;
    }
    .action_buttons {
      display: flex;
    }
  }
}

.confirm__actions_invisible {
  display: none;
  min-width: 0;
}

.confirm__actions_visible {
  display: block;
  min-width: 92px;
}
