.marked_day {
	background-color: #c6ccfe;
	border-radius: 50%;
}
.button_arrow {
	width: 7px;
	height: 12px;

	svg {
		width: inherit;
		height: inherit;
	}
}

.arrow_right,
.arrow_left {
	width: 16px;
	height: 12px;
}

.arrow_right {
	transform: rotate(180deg);
}
