.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 230px;
	width: 100%;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #C6CCFE;
  background: #FFFFFF;
  top: 4px !important;

  z-index: 9999;

  box-shadow: 0 0 20px 0 rgba(75, 94, 245, 0.15);

  &__item {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: var(--Primary-Light-Blue-2, #E4E7FF);
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &_title {
      font-family: Rubik, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #1C2F4C;
    }
  }
}