@import '~assets/styles/mixins';

.container {
  position: relative;
  padding: 11px 16px 15px 24px;
  border: 1px solid $primary-light;
  border-radius: 30px;
}

.textarea {
  @include inputTextArea;

  &:focus + .edit {
    opacity: 0;
  }
}


.edit {
  @include inputEdit;
  top: unset;
  bottom: 0;
}

