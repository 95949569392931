@import '~assets/styles/variables';

.statusForm {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 52px 0 52px;
  max-width: 828px;
  margin-top: 40px;

  &__feild {
    margin-bottom: unset;
    gap: 4px;
    min-height: unset;

    & > label {
      margin-bottom: unset;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    div:last-child span {
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: -0.3px;
    }
  }

  &__caption {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_limit {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: -0.3px;
      color: #5B768B;

      &_error {
        color: #D00074;
      }
    }
  }

  &__pickers {
    display: flex;
    gap: 24px;

    &__field {
      width: 240px;
    }
  }
}