@import '~assets/styles/variables';

.preview {
  font-family: "Plus Jakarta Sans", sans-serif;
  padding: 0 52px;

  &__confetti {
    position: relative;
    display: flex;
    width: 360px;
    height: 56px;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 267px;
      height: 138px;
      background-image: url("~assets/image/confetti.svg");
    }

    &:before {
      top: -38px;
      right: 187px;
    }

    &:after {
      top: -44px;
      right: -30px;
    }

    &_back {
      display: flex;
      justify-content: flex-start;
      padding: 8px;
      margin-left: 16px;

      svg {
        cursor: pointer;
        z-index: 2;
      }
    }
  }

  &__main_banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    width: 100%;
    border-radius: 12px;
    padding: 10px 16px;
    margin-bottom: 40px;

    background-position: bottom left;
    background-size: cover;
    background-repeat: no-repeat;

    &__dataBlock {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &_challengeTitle,
      &_challengeDescription,
      &_navText {
        color: #34343a;
        line-height: 24px;
      }

      &_challengeTitle {
        font-size: 19px;
        font-weight: 800;
      }

      &_challengeDescription {
        font-size: 14px;
        font-weight: 400;
      }

      &_navButton {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-top: 4px;

        span {
          font-size: 14px;
          font-weight: 600;
        }

        &_navArrow {
          width: 12px;
          height: 12px;
        }
      }

      &_imgBlock {
        max-width: 100px;
        max-height: 120px;
      }
    }
  }

  &__banner {
    position: relative;
    display: flex;
    padding: 0 16px;
    margin-bottom: 48px;

    &__dataBlock {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: -22px;
      left: 32px;
      right: 35px;
      bottom: 0;

      &_imgBlock {
        max-width: 100px;
        max-height: 120px;
      }

      &_title {
        max-width: 46%;
        margin-top: 22px;
        text-wrap: wrap;
        overflow-wrap: break-word;

        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;

        color: #34343A;
      }
    }
  }

  &__description {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    padding: 0 16px;
    margin-bottom: 40px;
    width: 100%;

    section {
      overflow-wrap: break-word;

      a {
        color: rgb(0, 0, 238) !important;
      }
    }

    &__confetti {
      &_top, &_bottom {
        position: absolute;
        z-index: 2;
      }

      &_top {
        top: -24px;
        left: 24px;
      }

      &_bottom {
        right: -24px;
        bottom: -24px;
      }
    }

    &_content {
      width: 301px;
      padding: 30px 24px;
      transform: rotate(1.961deg);
      background-color: $primary-bg;
      z-index: 1;
    }

    &_deadline {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      gap: 8px;
      margin-top: 15px;
      margin-bottom: 20px;
      width: max-content;
      border-radius: 34px;

      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      background-color: #FFF0DE;
      color: #D84E00;
    }
  }

  &__chesedIdeas {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;

    &_title {
      display: flex;
      gap: 8px;
      align-items: center;

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: $primary-main;
      }
    }

    &_item {
      display: flex;
      gap: 8px;

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #34343A;
        word-break: break-word;
      }
    }
  }

  &__button {
    border-radius: 100px !important;
    width: 328px !important;
    max-width: 328px !important;
    height: 52px !important;

    font-family: "Plus Jakarta Sans" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    font-style: normal;

    padding: 16px 32px !important;

    &_wrap {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 53px;
    }

  }
}
