.date__details_where {
  display: flex;
}

.date__ico {
  margin-right: 16px;

  svg {
    width: 11px;
    height: 11px;
  }

  .date__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
