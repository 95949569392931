@import "~assets/styles/variables";

.content {
  width: 300px;
  padding: 40px 20px 20px;
  position: relative;
  background: $white;
  border-radius: 4px 0px 0px 4px;
  text-align: center;
  margin: 0 6px 20px 0;

  &.active {
    border: 2px solid $primary-light;
  }
}
