.nav {
  position: absolute;
}

.policy {
  max-height: 65vh;
  padding: 30px 0;
  text-align: justify;
  word-break: break-word;

  a {
    color: inherit;

    &.blue {
      color: blue;
      text-decoration: none;
    }
  }

  ol {
    li {
      &:not(:nth-child(1)) {
        margin-top: 0.5rem;
      }
    }
  }
}

.content {
  margin-top: 3vh;
  font-size: 16px;
  line-height: 130%;
}

.article__text {
  margin-bottom: 30px;
  white-space: pre-line;
}

.title {
  margin: 30px 0;
  font-size: 20px;
  color: #f57f21;
}
