@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
  @include changeOpacityToVisibleAnimation;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  height: min-content;
  position: absolute;
  padding: 6.5vh 2.1vw;
  top: 0;
  left: 0;
  z-index: 10;

  &.unvisible {
    @include changeOpacityToUnvisibleAnimation;
  }

  .checkboxAll {
    display: flex;
    align-items: center;
  }

  .table {
    width: 100%;

    .checkAll {
      height: 16px;
      width: 16px;
      position: relative;
      padding-left: 25px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: $text-light;
    }

    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 2px solid $primary-light;
      background-color: $white;
      box-sizing: border-box;

      &.checked {
        background-color: $secondary-main;
        border: 2px solid $secondary-main;

        &::after {
          position: absolute;
          content: url('~assets/image/checkbox-arrow.svg');
          left: 1px;
          top: -4px;
          width: 8px;
          height: 6px;
        }
      }
    }
  }

  .scrollIcon {
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;

    opacity: 0;
    transition: opacity 0.3s ease;

    svg {
      width: 30px;
      height: 30px;
    }

    &_visible {
      opacity: 1;
    }
  }
}
