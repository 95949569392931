@import '~assets/styles/mixins';

.container {
  @include changeOpacityToVisibleAnimation;
  width: 100%;
  padding: 27px 34px;
  background-color: #fff;
  height: min-content;

  .table {
    margin-top: 22px;

    .loader {
      width: min-content;
      margin: 0 auto;
    }

    .fullname {
      max-width: none;
    }

    .main {
      overflow-x: clip;

      &__header {
        overflow: auto hidden;
        position: sticky;
        top: -2.55vh;
        height: 60px;
        z-index: 7;
      }

      &__body {
        overflow: auto hidden;
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }
  }

  .hideFilter {
    button {
      display: none;
    }
  }
}

.empty__list__message span {
  font-size: 17px;
}
