@import '~assets/styles/variables';

.snackbar {
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__icon {
     & > div:first-child {
      margin-right: 8px;
    }
  }

  &__title {
    font-size: 14px;
    color: $text-gray;
    font-weight: 500;
    line-height: normal;
  }

  &__description {
    font-size: 12px;
    color: $text-gray;
    font-weight: 400;
    line-height: normal;
  }
}