@import "~assets/styles/variables";
@import "~assets/styles/mixins";

.content {
  max-width: 607px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include changeOpacityToVisibleAnimation;

  &__title {
    margin-top: 4.4vh;
    margin-bottom: 9px;
  }

  &__message {
    margin: 20px 0;
  }

  &__subtitle {
    max-width: 300px;
    min-height: 40px;
    margin-top: 9px;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.3px;

    &_colleagues {
      max-width: 186px;
    }

    &_congrats {
      max-width: 355px;
    }
  }

  .container_gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65.8%;
    background: $gradients-orange;
    border-radius: 10px 0 0 0;
  }

  & > svg {
    z-index: 2;
  }
}
