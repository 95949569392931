.description__imageBlock {
  max-width: 100%;
  min-height: 200px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 6px 0px 10px;
  gap: 40px;

  &_image {
    width: 180px;
    height: 180px;
    border-radius: 12px;

    & > img {
      width: 180px;
      height: 180px;
      border-radius: inherit;
      object-fit: cover;
      object-position: center;
    }
  }
}

.video {
  width: 180px;
  height: 180px;
  border-radius: 12px;

  video {
    object-fit: cover !important;
  }
}