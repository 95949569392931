@import '~assets/styles/variables';

.wrap {
  max-width: 1280px;
  width: 100%;
  position: relative;
  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -0.208px;
    color: $text-main;
  }
}
.pointMap svg,
.pointMap object {
  width: 100%;
}
.info {
  position: absolute;
  top: 45px;
  left: 297px;
}

.pointBlock {
  margin-top: 8px;
}
.volunteerPoint__block {
  max-width: 50%;
  width: 100%;
  background-color: $container-bg;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  padding: 18px 18px;
  margin-bottom: 25px;
}
.volunteerPoint__description {
  width: 100%;
}
.volunteerPoint__ico {
  margin-right: 20px;
}
.volunteerPoint__status {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: $text-main;
  margin-bottom: 4px;
}

.volunteerPoint__points {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: $text-light;
  margin-bottom: 2px;
}

.volunteerPoint__pointsLeft {
  font-size: 12px;
  line-height: 130%;
  color: $text-light;
  margin-bottom: 2px;
}

.volunteerSpendTime {
  margin-top: 5px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: $text-light;
}

.volunteerPoint__share {
  width: min-content;
  height: min-content;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.userArr {
  .rookie {
    transform: translate(0px, 60px);
  }
  .secondGrade {
    transform: translate(85px, 140px);
  }
  .thirdGrade {
    transform: translate(-24px, 221px);
  }
  .fothGrade {
    transform: translate(86px, 304px);
  }
  .fifthGrade {
    transform: translate(234px, 372px);
  }
  .sixthGrade {
    transform: translate(100px, 920px) rotate(-90deg);
  }
  .seventhGrade {
    transform: translate(340px, 979px) rotate(-90deg);
  }

  .eighthGrade {
    transform: translate(434px, 859px) rotate(-90deg);
  }
  .ninethGrade {
    transform: translate(654px, 979px) rotate(-90deg);
  }

  .tenthGrade {
    transform: translate(775px, 859px) rotate(-90deg);
  }

  .eleventhGrade {
    transform: translate(869px, 979px) rotate(-90deg);
  }
  .tvelvthGrade {
    transform: translate(1416px, 810px) rotate(180deg);
  }

  .freshman {
    transform: translate(1416px, 660px) rotate(180deg);
  }
  .junior {
    transform: translate(995px, 557px) rotate(-90deg);
  }
  .senior {
    transform: translate(1275px, 16px) rotate(90deg);
  }
  .bachelor {
    transform: translate(1105px, 16px) rotate(90deg);
  }
  .master1 {
    transform: translate(575px, 556px) rotate(-90deg);
  }
  .master2 {
    transform: translate(895px, 81px) rotate(90deg);
  }
  .master {
    transform: translate(805px, -39px) rotate(90deg);
  }
}

.pointBlockItem {
  .done {
    fill: rgb(28, 47, 76);
    stroke: rgb(28, 47, 76);
  }
  .inProgress {
    fill: $primary-main;
    stroke: $primary-main;
  }
  .default {
    fill: transparent;
    stroke: $text-light;
  }
}
.level {
  .current {
    opacity: 1;
  }
}
