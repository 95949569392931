.main {
	width: 345px;
	position: sticky;
	top: 130px;
	max-height: 85vh;
	// height: calc(100vh - 150px);
	overflow: hidden;
	overflow-y: auto;
	border-radius: 15px;
}
