@import '~assets/styles/variables';

.item {
	min-width: 190px;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	font-size: 14px;
	line-height: 20px;
	color: $text-light;
	background-color: $primary-light02;
	border-radius: 10px 10px 0px 0px;
	margin: 0 4px;
	cursor: pointer;
	transition: all 0.2s;
	padding: 0 20px;
	user-select: none;

	&:first-child {
		min-width: unset;
	}

	&.smart_group {
		padding-left: 5px;

		.title {
			padding-left: 15px;
			padding-right: 5px;
		}
	}

	.title {
		width: 100%;
		height: 100%;
		padding: 20px 5px 20px 24px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&.signup_requests {
			padding-right: 10px;
		}
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&__counter {
		min-width: 29px;
		height: 23px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $primary-light;
		border-radius: 20px;
		font-size: 14px;
		line-height: 20px;
		padding: 10px;
	}

	.delete {
		width: 16px;
		height: 18px;
		position: absolute;
		background-color: $primary-light02;
		left: 7px;
		top: 7px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		z-index: 10;

		&:hover {
			background-color: #fff;
		}

		&__icon {
			width: 10px;
			height: 12px;
			object-fit: contain;
		}
	}

	&.active {
		background-color: #fff;
		color: inherit;
		.item__counter {
			background-color: $text-main;
			color: #fff;
		}

		.delete {
			background-color: #f8f9ff;

			&:hover {
				background-color: $primary-light02;
			}
		}
	}
}
