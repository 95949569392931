@import '~assets/styles/variables';

.additionalInfo {
  margin-block: 32px;

  &__text {
    color: $text-light;
    font-size: 22px;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.mainContent__subtitle {
  font-size: 18px;
  font-weight: 500;
}
