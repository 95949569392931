@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.input__search {
  position: relative;
  z-index: 3;

  input {
    @include inputMain;
    padding-left: 38px;
    z-index: 3;
    position: relative;

    &:focus {
      border: 1px solid #ffa133;
    }

    &:not(:focus) {
      & + .input__search_edit {
        opacity: 1;
      }
    }
  }

  &_edit {
    @include inputEdit;
  }

  &_icon {
    position: absolute;
    left: 15px;
    top: 12px;
    width: 14px;
    height: 14px;
    opacity: 1;
    transition: 0.2s;
    background-image: url('~assets/image/inputs/search.svg');
    cursor: pointer;
    z-index: 4;
  }

  &.big {
    max-width: 260px;
    width: 100%;
    height: 40px;
  }
}

.wrapper {
  position: relative;
  z-index: 2;
}

.dropWrap {
  width: 100%;
  height: auto;
  border: 1px solid #ffa133;
  border-radius: 0 0 30px 30px;
  position: absolute;
  top: 20px;
  z-index: 2;
  overflow-y: hidden;
}

.dropContainer {
  width: 100%;
  max-height: 264px;
  background-color: #ffff;
  overflow-y: auto;

  .dropDown {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
    padding: 5px 10px 5px 17px;
    cursor: pointer;

    &:first-child {
      padding-top: 22px;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #f1f3ff;
    }
    &.suspended {
      background-color: #ffa133;
      pointer-events: none;
    }

    &_Item {
      display: flex;
      align-items: center;

      & img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        border-radius: 50%;
      }
    }
  }
}
