@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.switchView__item {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  width: 100%;

  &__hashtag {
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 4px;
    font-style: italic;
    color: $primary-main;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.mainContent__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.208px;
  color: $text-main;
  margin-bottom: 32px;
  word-wrap: break-word;
  @include multilineThreeDots(3);
}

.mainContent__description {
  word-break: break-word;
  font-size: 18px;
  line-height: 130%;
  color: $text-light;
  margin-top: 10px;
  margin-bottom: 30px;
}

.mainContent__when,
.mainContent__where {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.ico__item {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f3ff;
  margin-right: 21px;

  svg {
    width: 22px;
    height: 24px;

    path {
      fill: #214ba5;
    }
  }
}

.mainContent__when,
.mainContent__where {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: $text-light;
}

.description__block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disabled {
  pointer-events: none;
}

.mainContent__subtitle {
  font-size: 18px;
  font-weight: 500;
  color: $text-main;
}
