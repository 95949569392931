.hashtag__option {
  display: flex;
  align-items: center;
  .MuiAutocomplete-option[data-focus='true'] {
    background-color: transparent;
  }
  &__image {
    max-width: 14px;
    max-height: 14px;
    margin-right: 2px;
    & > svg {
      width: 100%;
      height: 100%;
      & > path {
        fill: #000;
      }
    }
  }
}
