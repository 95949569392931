.logo {
	&__container {
		display: block;
		// height: 69px;
		// align-self: center;
	}
	&_disable {
		pointer-events: none;
	}
}
