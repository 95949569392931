@import '~assets/styles/variables';

.actions {
	z-index: 100;
	position: absolute;
	width: 220px;
	background: #fff;
	border: 1px solid $primary-bg;
	box-shadow: -2px -2px 4px rgba(241, 243, 255, 0.3);
	border-radius: 8px;
	top: 0;
	right: 0;
	bottom: unset;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.action {
		width: 100%;
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 12px 8px;
		border-radius: 5px;

		&:hover {
			background-color: $primary-bg;
		}

		&__icon {
			width: 21px;
			height: 21px;
			background-color: $primary-bg;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 13px;

			img {
				width: 10px;
				height: 10px;
				object-fit: cover;
			}
		}

		&__text {
			font-size: 18px;
			line-height: 20px;
		}
	}
}
