@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.input__search {
	width: 100%;
	position: relative;
	z-index: 3;

	input {
		@include inputMain;
		padding-left: 34px;
		z-index: 3;
		position: relative;

		&:focus {
			border: 1px solid $secondary-main;
		}

		&:not(:focus) {
			& + .input__search_edit {
				opacity: 1;
			}
		}
	}

	&_edit {
		@include inputEdit;
	}

	&_icon {
		position: absolute;
		left: 15px;
		top: 12px;
		width: 14px;
		height: 14px;
		opacity: 1;
		transition: 0.2s;
		background-image: url('~assets/image/inputs/search.svg');
		cursor: pointer;
		z-index: 4;
	}

	&.big {
		max-width: 260px;
		width: 100%;
		height: 40px;
	}
}

.wrapper {
	position: relative;
	z-index: 2;
}
.dropWrap {
	position: absolute;
	width: 100%;
	max-height: 225px;
	background-color: #fff;
	z-index: 2;
	padding-top: 10px;
	overflow: hidden;
	border-radius: 30px;
	border: 1px solid $secondary-main;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-top: 0;
	box-shadow: none;
	top: 18px;
	overflow-y: hidden;

	.listWrapper {
		overflow-y: auto;
		max-height: 225px;

		.dropDown {
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			line-height: 30px;
			text-align: left;
			border-bottom: 1px solid #e6e6e6;
			padding: 5px 10px;
			padding-left: 17px;
			cursor: pointer;

			&:first-child {
				padding-top: 15px;
				border-top: none;
			}

			&:last-child {
				padding-bottom: 20px;
				border-bottom: none;
			}

			&:hover {
				background-color: $primary-bg;
			}
		}
	}
}
