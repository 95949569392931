.wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.link {
  font-size: 18px;
  line-height: 23px !important;
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}

.icon {
  width: 20px;
  cursor: pointer;
}