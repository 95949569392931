@import '~assets/styles/variables';

.create__section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  &__title {
    font-size: 20px;
  }

  &__content {
    position: relative;
  }

  &__autocomplete {
    width: 100%;
    background-color: $white;
    border-radius: 30px;
    height: 40px;
    border: 1px solid $primary-light;

    & > div > div > div > button > span:first-child > svg {
      & > path {
        fill: $primary-main;
      }
    }
    & > div > div > div > button {
      margin: 8px 7px 0px 0px;
      &:hover {
        background-color: none;
      }
    }
    & > div > div > div > button > span:last-child {
      display: none;
    }
    & > div > fieldset {
      display: none;
    }
  }

  &__textField {
    height: 0px;
    padding-left: 15px !important;

    & input {
      width: 100%;
      height: 6px !important;
      padding-left: unset !important;
      padding: 7.5px 4px 7.5px 0 !important;
    }
    & fieldset {
      border: none;
    }
    & > div input {
      font-family: 'Rubik', sans-serif;
      font-weight: normal;
      font-size: 18px;
      color: #1c2f4c;
    }
    & > div input::placeholder {
      color: #5b768b;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 18px;
      opacity: 1;
    }
  }

  &__add__icon {
    display: none;
    cursor: pointer;
    position: absolute;
    width: 28px;
    height: 28px;
    & > svg {
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: $primary-bg;
      border-radius: 50%;
    }
    &:active {
      background-color: $primary-light;
    }
    right: 40px;
    padding: 3px;
    top: 6px;
  }

  &__add__icon_visible {
    display: block;
  }

  &__list__wrap {
    overflow-y: auto;
    max-height: 16vh;
  }

  &__list {
    padding-left: 0px;
    margin: 0px;
    width: 100%;
    & > li {
      &:hover {
        background-color: $primary-bg;
      }
      &[aria-selected='true'] {
        background-color: $primary-bg;
      }
      & > div > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #1c2f4c;
        font-family: 'Rubik', sans-serif;
        font-weight: normal;
        font-size: 18px;
      }
    }
  }
}

.hashtag__option {
  display: flex;
  align-items: center;
  width: 100%;
  .MuiAutocomplete-option[data-focus='true'] {
    background-color: transparent;
  }
  &__image {
    max-width: 14px;
    max-height: 14px;
    min-width: 14px;
    min-height: 14px;
    margin-right: 2px;
    & > svg {
      width: 100%;
      height: 100%;
      & > path {
        fill: #1c2f4c;
      }
    }
  }
}

.icon {
  width: 7px;
  height: 9px;
  overflow: visible;
  transform: rotate(-90deg);
}
