@import '~assets/styles/variables';

.create__section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;

  &__title {
    font-size: 20px;
  }

  &__content {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    max-width: 480px;
  }

  &__autocomplete {
    width: 340px;
    background-color: $white;
    border-radius: 30px;
    min-height: 41px;
    border: 1px solid $primary-light;

    input {
      padding: 11px 4px 10px 6px !important;
      text-overflow: unset;
    }

    & > div > div > div > button > span:first-child > svg {
      & > path {
        fill: $primary-main;
      }
    }
    & > div > div > div > button {
      &:hover {
        background-color: $primary-bg;
      }
    }
    & > div > fieldset {
      display: none;
    }
  }

  &__textField {
    height: 0px;
    & input {
      width: 100%;
      height: 0px !important;
    }
    & fieldset {
      border: none;
    }
    & > div input {
      color: $text-main;
    }
    & > div input::placeholder {
      color: #1c2f4c;
      padding-left: 2px;
      font-size: 18px;
      font-weight: 500;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__add__icon {
    display: none;
    cursor: pointer;
    position: absolute;
    width: 28px;
    height: 28px;
    & > svg {
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: $primary-bg;
      border-radius: 50%;
    }
    &:active {
      background-color: $primary-light;
    }
    right: 40px;
    padding: 3px;
    top: 6px;
  }

  &__add__icon_visible {
    display: block;
  }

  &__list__wrap {
    overflow-y: auto;
    max-height: 12vh;
  }

  &__list {
    padding-left: 0px;
    margin: 0px;
    width: 100%;
    & > li {
      &:hover {
        background-color: $primary-bg;
      }
      &[aria-selected='true'] {
        background-color: $primary-bg;
      }
      & > div > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.error__wrapper {
  position: absolute;
  top: 40px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
