.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table {
  &__header,
  &__body &__row {
    display: grid;
    grid-template-columns: 60px 35% 24% 25%;
    grid-gap: 10px;
  }

  &__name {
    max-width: none;
  }
}

.error_message {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
