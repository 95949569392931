@import '~assets/styles/mixins';

.content {
	max-width: 400px;
	width: 100%;
	text-align: center;
	@include changeOpacityToVisibleAnimation;
	margin-top: 6vh;

	.title {
		display: block;
		font-size: 18px;
		margin-bottom: 3.5vh;
	}
}
