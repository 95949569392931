@import '~assets/styles/variables';
@import '~assets/styles/mixins';

button.btn_default {
  @include default-button;
  @include font-button;
  transition: all 0.2s ease;

  &.lg {
    max-width: 260px;
    width: 100% !important;
  }

  &.md {
    max-width: 190px;
    width: 100%;
  }

  &.extra_md {
    max-width: 223px;
    width: 100%;
  }

  &.extra_lg {
    max-width: 300px;
    width: 100%;
  }

  &.sm {
    max-width: 134px;
    width: 100%;
  }

  &.nano {
    max-width: 70px;
    width: 100%;
    height: 40px;
  }

  &.center {
    margin: 0 auto;
  }

  &.orange {
    border: 1px solid $secondary-main;
    background-color: $secondary-main;
    color: $white;
    box-shadow: 2px 4px 4px rgba(33, 75, 165, 0.2);
  }

  &.grey {
    //border: 1px solid $t;
    background-color: $primary-bg;
    color: $text-light;
  }

  &.primary,
  &.primary:hover {
    border: 1px solid $primary-main;
    background-color: $primary-main;
    box-shadow: 2px 4px 4px rgba(33, 75, 165, 0.2);
  }

  &.primary {
    color: $white;
    letter-spacing: -0.3px;

    &:active {
      background-color: $primary-dark;
    }
  }

  &.secondary,
  &.secondary:hover {
    border: 1px solid $primary-bg;
    background-color: $primary-bg;
    color: $primary-main;
  }

  &.secondary {
    transition: background-color 0.2s;

    &_active {
      background-color: $primary-light;
      color: $primary-dark;

      &:hover {
        background-color: $primary-light;
        color: $primary-dark;
      }
    }

    &:active {
      background-color: $primary-light;
      color: $primary-dark;
    }

    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  &.submit,
  &.submit:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 375px;
    width: 100%;
    height: 66px;
    border: none;
    border-radius: 0;
    background-color: $primary-bg;
    color: $primary-main;
    box-shadow: 0px -4px 4px rgba(241, 243, 255, 0.3);
  }

  &.submit {
    &:active {
      background-color: $primary-light;
      color: $primary-dark;
    }
  }

  &.apply {
    bottom: 10px;
    transform: translateY(10px);
    z-index: 99;
  }

  &.publish {
    height: 48px;
  }

  &.not_active {
    background: $primary-light02;
    color: $primary-main;
  }

  &.outline_border {
    width: 72px;
    height: 22px;
    border: 1px solid $primary-light;
    border-radius: 50px;
    color: $primary-main;
    font-size: 10px;
  }
  &.no_click {
    pointer-events: none;
  }

  &.disabled {
    border: none;
    background-color: $disabled;
    color: $text-light;
    cursor: default;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    &:active {
      background-color: $disabled;
    }
  }

  &.text {
    background-color: transparent;
    color: $primary-main;
    width: fit-content;
    height: fit-content;

    &_disabled {
      border: none;
      background-color: transparent;
      color: $text-light;
      cursor: default;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      &:active {
        background-color: transparent;
      }
    }
  }
}
