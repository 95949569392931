@import '~assets/styles/mixins';

.feed__main {
  min-height: 638px;
  overflow: hidden;

  .feed__sidebarSearch {
    padding: 28px;
    width: 348px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &__relevant__opportunities {
    span {
      font-weight: 700;
      font-size: 16px;
      margin-top: -10px;

      &:nth-child(2n) {
        margin-bottom: 10px;
      }
    }
  }
}
