.image {
  position: relative;
  width: 130px;
  height: 130px;
  flex: 1 0 auto;
  margin-right: 36px;

  &__logo {
    width: 100%;
    height: 100%;
    border-radius: 15px;

    &_min {
      width: 30px;
      height: 30px;
      max-width: none;
      position: absolute;
      z-index: 2;
      right: 10px;
      bottom: 10px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.feedDistance {
	height: 32px;
	line-height: 32px;
	padding: 0 10px;
	background: #ffffff;
	border: 1px solid #f1f3ff;
	border-radius: 2px;
	font-size: 14px;
	text-align: center;
	color: #1c2f4c;
}