@import '~assets/styles/variables';
@import '~assets/styles/mixins';

button.outlined {
  @include button_outlined;
}

.main {
  max-width: 112px;
  display: flex;
  flex-direction: row;
  height: 57px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &__actions {
    display: flex;
    justify-content: center;
    width: 100%;

    button:last-child {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  &__contact {
    align-self: center;
  }
}
