.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding: 10px 16px;

  background-position: bottom left;
  background-size: cover;
  background-repeat: no-repeat;

  .dataBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .challengeTitle,
    .challengeDescription,
    .navText {
      color: #34343a;
      line-height: 24px;
    }

    .challengeTitle {
      font-size: 19px;
      font-weight: 800;
    }

    .challengeDescription {
      font-size: 14px;
      font-weight: 400;
    }

    .navButton {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 4px;
      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: 600;
      }

      .navArrow {
        width: 12px;
        height: 12px;
      }
    }
  }

  .imgBlock {
    max-width: 100px;
    max-height: 108px;
  }
}
