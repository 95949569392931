@import "~assets/styles/variables";

.main {
  min-height: 100vh;
  padding: 30px 45px 99px;
  background-color: $container-bg;
  margin: 0 auto;
}

.container {
  margin-bottom: 41px;

  .title {
    margin-top: 26px;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: $text-dark;
    text-align: center;
  }
}
.content__list {
  overflow-x: auto;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .main {
    padding: 30px 15px;
  }
}
