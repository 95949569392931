@import '~assets/styles/variables';
.container {
	display: flex;
	max-width: 265px;
	width: 100%;
	justify-content: flex-end;
	// todo if enable lang switch - uncomment
	// justify-content: space-between;
	position: relative;
}

a.link,
a.link:visited {
	display: flex;
	align-items: center;
	margin-left: 8px;
	color: $primary-main;
	text-decoration: none;
}
.linkText {
	margin-left: 5px;
}
