$primary-main: #214ba5;
$primary-dark: #0017d0;
$primary-light: #c6ccfe;
$primary-light02: #e4e7ff;
$primary-bg: #E4E7FF;

$secondary-main: #ffa133;
$secondary-dark: #ef8100;
$secondary-light: #ffdba5;
$secondary-bg: #fff4e4;

$text-main: #1c2f4c;
$text-light: #5b768b;
$text-dark: #072c56;
$text-gray: #6C6E7E;

$white: #ffffff;
$disabled: #eff1f3;

$gradients-blue: linear-gradient(180deg, #f1f3ff 0%, rgba(241, 243, 255, 0) 100%);
$gradients-orange: linear-gradient(180deg, #fff4e4 0%, rgba(255, 244, 228, 0) 100%);

$container-bg: #f8f9ff;
$contayner-bg-tariff: #e5e5e5;

$z-select-options: 100;

$organization-table-sidebar-width: 360px;

$required: #D00074;
