@import './keyframes';
@import './variables';

//page
@mixin orgPage {
  padding: 2.55vh 1.5vw;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

//button
@mixin default-button {
  max-width: 134px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 16px;
  cursor: pointer;
  outline: none;
}
@mixin font-button {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;
}

@mixin button_outlined {
  @include font-button;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40px;
  min-width: 40px;
  padding: 4px 6px;
  border: 1px solid $primary-light;
  border-radius: 50px;
  background: $white;
  font-size: 10px;
  color: $primary-main;
  transition: all 0.2s ease;
  text-transform: none;
  button {
    width: 100%;
  }
}

@mixin skipButton {
  position: absolute;
  top: 34px;
  right: 34px;
  padding: 0 5px;
  color: $primary-main;
  text-decoration: none;
}

//input
@mixin inputMain {
  padding: 8px 38px 8px 24px;
  width: 100%;
  outline: none;
  border: 1px solid $primary-light;
  border-radius: 30px;
  font-size: 18px;
  line-height: normal;
  transition: all 300ms;
  color: $text-main;

  &[dir='rtl'] {
    padding: 8px 24px 8px 38px;
  }

  &::placeholder {
    opacity: 1;
    color: $text-light;
    // padding-top: 12px;
  }

  &:focus {
    border-color: #ffa133;
    transition: border-color 300ms;
  }

  &:disabled {
    background-color: $disabled;
    color: #b1b7bb;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 50px white inset !important;
    font: 400 18px 'Rubik';
    color: $text-main;
  }
}
@mixin inputEdit {
  position: absolute;
  right: 16px;
  top: 12px;
  width: 16px;
  height: 16px;
  opacity: 1;
  transition: 0.2s;
  background-image: url('../image/inputs/edit.svg');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  &[dir='rtl'] {
    right: unset;
    left: 16px;
  }
}
@mixin inputTextArea {
  padding: 0 8px 0 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-size: 18px;
  line-height: normal;
  transition: all 300ms;
  resize: none;
  color: $text-main;

  &[dir='rtl'] {
    padding: 0 0 0 8px;
  }

  &::placeholder {
    color: $text-light;
    opacity: 1;
  }

  &:focus {
    border-color: #ffa133;
    transition: border-color 300ms;
  }

  &:not(:focus) {
    & + .input_textarea__edit {
      opacity: 1;
    }
  }

  &:disabled {
    background-color: $disabled;
    color: #b1b7bb;
  }
}

//animations
@mixin changeOpacityToVisibleAnimation {
  animation: opacityToVisible 0.2s linear;
}
@mixin changeOpacityToUnvisibleAnimation {
  animation: opacityToUnvisible 0.2s linear;
}
@mixin changeTransformFromRight {
  animation: transformFromRight 0.2s linear;
}
@mixin changeTransformToRight {
  animation: transformToRight 0.2s linear;
}

//scroll
@mixin scrollDisable {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollStyle {
  /* width */
  &::-webkit-scrollbar {
    width: 50px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
}

//form
@mixin loginContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@mixin onboardingForm {
  max-width: 335px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin signUp-subtitle {
  margin-top: 8px;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.3px;
  white-space: pre-wrap;
}
@mixin inputContainer {
  width: 100%;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;

  label {
    display: block;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 23px;
  }
}
@mixin form-error-hint {
  @include changeOpacityToVisibleAnimation;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #d00074;
}

@mixin popupLabel {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.3px;
  margin-bottom: 10px;
}

//TODO: remove
@mixin threeDots($w: 200px, $h: 21px) {
  max-width: $w;
  height: $h;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin lineThreeDots() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin multilineThreeDots($lineClamp: 2) {
  //do not use with padding block
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
}
