@import 'src/assets/styles/variables';

.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $container-bg;

  & > div {
    flex-grow: 1;
  }

  & h2 {
    padding-block: 20px;
    align-self: center;
  }
}
