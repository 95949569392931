.chatWrapper {
  display: flex;
  height: 84vh;
  overflow: hidden;

  &.org {
    max-height: 77vh;
  }
}

.broadcastingInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
