.action {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  position: relative;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
