.list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	max-height: 590px;
}
.listItem {
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 500;
	border-bottom: 1px solid #c6ccfe;
	max-width: 377px;
	padding: 0 5px;
	& > * {
		font-weight: 400;
	}
}
.noborder {
	border: none;
}
