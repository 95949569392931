@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.main {
	width: 100%;
	display: grid;
	grid-template-rows: 100px 1fr;
	min-height: 100vh;
	background: $container-bg;
	gap: 10px;
	@include changeOpacityToVisibleAnimation;

	&.disabledGrid {
		display: flex;
		flex-direction: column;
		gap: 0px;
	}

	&__content {
		padding: 2vh 3.13vw;
		width: 100%;
		position: relative;
		display: flex;
		grid-column-start: 1;
		grid-column-end: 3;
		max-width: 1280px;
		margin: 0 auto;

		&.fullWidth {
			max-width: unset
		}

		width: 100%;
		& > h1 {
			margin: 0;
			margin-bottom: 30px;
		}
	}
}
