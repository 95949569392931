@import '~assets/styles/_mixins.scss';

.content {
  @include loginContent;
}

.icon {
  display: none;
  cursor: pointer;
  margin: 4px 0;
  height: 44px;
  width: 152px;

  &.allowIcon {
    display: block;
  }
}

@media (max-width: 500px) {
  .icon {
    display: block;
  }
}
