@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.feed__main {
  min-height: 638px;
  max-width: 945px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  padding: 36px;
  position: relative;
}
.feed__sidebar {
  min-height: 70vh;
}

.item__feed__hashtag {
  margin-top: 4px;
  font-style: italic;
  color: $primary-main;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.feed__sidebarSearch,
.feed__sidebarFilter {
  padding: 28px;
  width: 348px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}
.content__link_back {
  grid-column: 1 / span 2;
  width: 70px;
}
.feed__view {
  width: 100%;

  & > div {
    grid-template-columns: 3fr 1fr;
  }
}
.feed__sidebar {
  background: #f1f3ff;
  border-radius: 8px;
  padding: 28px;
}
.feed__mainHeaderAction {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
}
.feed__mainContent {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.feed__mainFooterAction {
  margin-top: 10px;
}
.feedDetails__organization {
  display: flex;
  align-items: center;
  margin-bottom: 64px;
}
.organization__logo {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}
.organization__contact {
  display: flex;
  flex-direction: column;
}
.organization__link,
.organization__phone,
.organization__mail {
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.208px;
  text-decoration-line: underline;
  color: #5b768b;
}
.organization__phone {
  margin-bottom: 30px;
}

.organization__title,
.feedDetails__user {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #1c2f4c;
  margin-bottom: 20px;
  word-break: break-word;
  @include multilineThreeDots(3);
}
.organization__title {
  margin-bottom: 38px;
}
.feed__mediaContent {
  margin-top: 43px;
  width: 266px;
  height: 268px;
  border-radius: 8px;
}
.feedDetails__description {
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.3px;
  color: #5b768b;
  margin-bottom: 30px;
  word-break: break-word;
  @include multilineThreeDots(5);
}

.wrapFeed__team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.loader {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.volunteersLimit {
  display: flex;
  align-items: center;
  &__title {
    margin-left: 36px;
    margin-right: 6px;
    font-weight: 700;
    font-size: 16px;
  }

  &__wrapper {
    max-width: 100px;
  }
}

.shareLink {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
