@import '~assets/styles/variables';

.hashtags {
  &__title {
    text-align: center;
    font-size: 20px;
  }

  &__content {
    max-width: 480px;
    margin: 0 auto;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    overflow-x: hidden;
    border: 1px solid $primary-light;
    background-color: $white;
    border-radius: 12px;

    &_wrapper {
      width: 100%;
      overflow-x: hidden;
      max-height: 190px;
      overflow-y: auto;
      padding: 4px;
    }
  }
}
