@import '~assets/styles/mixins';

.appeal {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 4.4vh;

	&__subtitle {
		@include signUp-subtitle;

		max-width: 355px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-top: 3.6vh;
		margin-bottom: 2vh;
	}
	.link_text img {
		max-width: 200px;
		width: 100%;
	}
}
