@import '~assets/styles/_mixins.scss';

.main {
  @include form-error-hint;
  z-index: 0;
}

.error_address {
  display: flex;
  flex-wrap: wrap;
}
