@import '~assets/styles/variables';

.wrap {
  position: relative;
  max-width: 1280px;
  width: 100%;

  .mainBlock {
    display: flex;
    width: 100%;
    margin-top: 24px;
    gap: 32px;

    .challengesData,
    .backgroundWrap {
      height: 100%;
      width: 50%;
      border-radius: 12px;
    }

    .backgroundWrap {
      background: linear-gradient(328.27deg, #7c47ff 48.17%, #6046ff 70.91%, #7c47ff 80.26%, #9c5fff 90.31%);
      border-radius: 12px;

      .userInfoData {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 0 0 0 16px;

        .avatar {
          width: 64px;
          min-width: 64px;
          height: 64px;
          min-height: 64px;
          object-fit: cover;
          border-radius: 50%;
          cursor: pointer;
        }

        .statsData {
          background-color: #00000033;
          border-radius: 12px;
          padding: 12px;
          width: 100%;

          hr {
            background-color: #00000026;
          }
        }
      }
    }

    .challengesData {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
      max-height: 118.39px;
    }
  }

  .challengesPreview {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .mobileBlock {
    flex-direction: column;

    .backgroundWrap,
    .mobileUserData {
      width: 100%;

      .mobileUserData {
        gap: 12px;

        .mobileAvatar {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
        }
      }
    }

    .mobileChallengesData {
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -0.208px;
    color: $text-main;
  }
}
