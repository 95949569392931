@import "~assets/styles/mixins";

.container {
  @include orgPage;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5vh;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .right_side,
  .left_side {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .right_side {
    max-width: 490px;
    width: 100%;
  }
  .left_side {
    width: 100%;
  }
}
.container_block {
  max-width: none;
  padding: 38px;
  height: auto;
  position: relative;

  .chartInfo_many {
    position: absolute;
    top: 38px;
    right: 38px;
    display: flex;

    & > div:first-child {
      margin-right: 15px;
    }
  }
}
