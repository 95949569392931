@import '~assets/styles/variables';

.hashtag {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__chip {
    background-color: transparent !important;
    position: relative !important;

    & > span {
      padding-left: 7px;
      max-width: 200px !important;
    }
    & > svg:first-child {
      & path {
        fill: #000;
      }
    }
    & > svg:last-child {
      display: none;
      &:hover {
        & path {
          fill: $white;
        }
      }
    }
  }

  &__icon {
    width: 10px;
    height: 10px;
  }

  &__edit__icon {
    display: none;
    cursor: pointer;
    align-items: center;
    position: relative;
    left: -20px;
    top: 0px;
    &:hover {
      & > svg {
        margin-right: 10px;
        &:hover {
          & path {
            fill: $white;
          }
        }
      }
    }
  }

  &:hover {
    background-color: $primary-light02 !important;
    border-radius: 14px;
    .hashtag__chip > svg:last-child {
      display: block;
    }

    .hashtag__edit__icon {
      display: flex;

      & > svg {
        margin-right: 10px;
      }
    }
  }
}
