@import "~assets/styles/variables";

.switchCheckbox {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 2.4vh;
  }

  &__label {
    &_active {
      cursor: pointer;
      color: $primary-main;
      text-decoration: underline;
    }
  }
}
