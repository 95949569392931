@import '~assets/styles/mixins';

.form {
	width: 100%;

	.input__container {
		@include inputContainer;
	}
}

@media screen and (max-width: 1000px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
