.nav {
	position: absolute;
}
.wrap2 {
	justify-content: center;
}
.title {
	font-weight: 500;
	font-size: 24px;
	line-height: 110%;
	letter-spacing: -0.208px;
	color: #1c2f4c;
	margin-bottom: 27px;
	margin-top: 27px;
}
.description {
	max-width: 381px;
	width: 100%;
	text-align: center;
	margin-bottom: 61px;
	font-size: 22px;
	line-height: 130%;
	text-align: center;
	color: #1c2f4c;
}
