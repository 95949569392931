@import '~assets/styles/variables';

.marked_day {
  background-color: $primary-light;
  border-radius: 50%;
}
.button_arrow {
  width: 7px;
  height: 12px;

  svg {
    width: inherit;
    height: inherit;
  }
}

.datePicker__emptyLabel {
  & > div > input {
    color: $text-light;
    &::placeholder {
      opacity: 1;
    }
  }
}

.readOnly__input {
  & > div > input {
    cursor: default;
  }
}
