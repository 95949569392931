@import '~assets/styles/variables';

.form {
  position: relative;
  background-color: #fff;
  max-width: 324px;
  padding: 32px 16px;
  border-radius: 10px;
  border: 1px solid $primary-light;
  color: $text-light;
  font-size: 14px;
}

.submit {
  width: auto !important;
  padding-inline: 19px !important;
  height: 32px !important;
  margin-top: 24px !important;
  font-size: 16px !important;
}
