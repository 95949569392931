@import "~assets/styles/variables";

.dots {
  max-width: 24px;
}

.actions {
  z-index: 100;
  position: absolute;
  width: 225px;
  background: #fff;
  border: 1px solid $primary-bg;
  box-shadow: -2px -2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 8px;
  transform: translateY(130px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: -280px;
  height: min-content;

  .action {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 8px;
    border-radius: 5px;
    &.disabled {
      background-color: #cecece !important;
      opacity: 0.2;
    }

    &:hover {
      background-color: $primary-bg;
    }

    &__icon {
      width: 21px;
      height: 21px;
      background-color: $primary-bg;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 13px;

      img {
        width: 10px;
        height: 10px;
        object-fit: cover;
      }

      &_delete {
        width: 21px;
        height: 21px;
        margin-right: 13px;

        img {
          width: 21px;
          height: 21px;
        }
      }

      &_edit {
        width: 21px;
        height: 21px;
        margin-right: 13px;
        background-color: $primary-bg;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 9px;
          height: 9px;
        }
      }
    }

    &__text {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
