.content {
  max-width: 900px;
  width: 100%;

  &__header {
    max-width: 900px;
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    &__chesed {
      display: block;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.208px;
      margin-top: 12px;
      max-width: 540px;
    }

    button {
      margin-left: auto;
    }
  }
}

.button_orange {
  background-color: #ffa133;
}
