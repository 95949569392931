.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;

  :global {
    .MuiFormControlLabel-label span {
      display: flex;
      justify-content: flex-start;
    }
  }
}

.text {
  font-size: 14px;
}

.input {
  height: 40px;

  //to remove arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield; /* Firefox */
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
  }

  & > input {
    padding: 0;
    height: 32px;
    width: 52px;
    text-align: center;
  }
}

.container {
  position: static;
  height: 32px;
  min-height: auto;
  margin: 0;
}

.error {
  position: absolute;
  left: 28px;
}
