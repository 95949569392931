@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.dropdown_select {
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px solid $primary-light;
  border-radius: 30px;
  transition: border 0.5s, border-radius 0.3s;
  font-size: 18px;
  line-height: 20px;

  &.md {
    max-width: 255px;
  }

  &.maxWidthNone {
    max-width: none;
  }

  &__options {
    height: auto;
    z-index: 99;
    display: none;
    z-index: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid $primary-bg;

    .smartGroup_optionTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: inherit;

      &__name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }

      &__count {
        max-width: fit-content;
        width: 100%;
        font-size: 12px;
        padding-left: 10px;
        color: $text-light;
      }
    }
  }

  &__options > div,
  &__title {
    min-height: 40px;
    width: 100%;
    outline: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__title {
    position: relative;
    border-radius: 30px;
    color: $text-light;
    transition: background-color 0.3s, color 0.3s, border-radius 0.3s;
    position: relative;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 80%;
      overflow: hidden;
    }

    &_selected {
      color: inherit;
    }

    &__vector {
      position: absolute;
      right: 17px;
      display: flex;
      justify-content: space-between;
      width: 7px;
      height: 9px;
      overflow: visible;
      transform: rotate(-90deg);
    }
  }

  &__active {
    position: relative;
    border: 1px solid transparent;

    .dropdown_select {
      height: inherit;
      z-index: 99;
      overflow-y: auto;

      &__title {
        height: 40px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: $secondary-main;
        color: #fff;
        transition: background-color 0.3s, color 0.3s, border-top-left-radius 0.2s, border-top-right-radius 0.2s;
        position: relative;

        &__vector {
          transform: rotate(0deg);
        }
      }

      &__options {
        max-height: calc(34vh - 20px);
        z-index: 99;

        width: 100%;
        position: relative;
        display: block;
        @include changeOpacityToVisibleAnimation;
        overflow-y: auto;

        &__item {
          display: flex;
          align-items: center;

          &__text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        .dropdown_select__options__item_coordinator {
          display: flex;
          align-items: baseline;
          flex-direction: column;

          .dropdown_select__options__item__text_coordinator {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            margin: 4px 0 0 0;
            font-weight: 500;
          }

          .dropdown_select__options__item__email_coordinator {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $text-light;
            margin: 0 0 4px 0;
            font-size: 14px;
          }
        }

        &.fullHeight {
          max-height: unset;
        }

        div {
          background-color: #fff;
          border-bottom: 1px solid $primary-bg;

          &.roles {
            text-transform: capitalize;
          }
        }

        div:hover {
          background-color: $primary-bg;

          .smartGroup_optionTitle {
            background-color: inherit;
          }
        }

        div:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.LangSwitch__image {
  width: 74px;
  cursor: pointer;
}
.wrap_langSwitch {
  display: flex;
  align-items: center;
  position: relative;
  .dropdown_select {
    border: none;
    &__options {
      position: absolute;
      top: 100%;
      z-index: 99;
    }
    &__title,
    &__options__item {
      padding-left: 15px;
      padding-right: 12px;
    }
    &__options__item_notActive {
      background-color: red;
    }
  }
  .dropdown_select__active {
    .dropdown_select__title_selected span {
      color: #fff !important;
    }
  }
}

.disabled {
  background-color: #eee;
  border-radius: 30px;
  color: #b1b7bb;
  pointer-events: none;
}

.disabledLikeInput {
  span {
    color: #b1b7bb;
  }
}
