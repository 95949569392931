@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	@include onboardingForm;

	.title {
		display: block;
		margin: 0 auto;
		margin-top: 3.3vh;
		margin-bottom: 2.1vh;
	}

	.subTitle {
		text-align: center;
		margin-bottom: 3vh;
	}

	.form {
		@include onboardingForm;

		&__link,
		&__link:visited {
			max-width: 160px;
			width: 100%;
			display: block;
			color: $text-light;
		}
	}
}
