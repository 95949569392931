@import '~assets/styles/variables';

.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;

  .title {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__icon {
      width: 18px;
      height: 12px;
      object-fit: fill;
      margin-right: 13px;
    }

    &__text {
      color: $primary-main;
      font-size: 16px;
      line-height: 21px;
    }
  }

  .clear {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__icon {
      width: 16;
      height: 18px;
      object-fit: fill;
      margin-right: 8px;
    }

    &__text {
      color: $text-light;
      font-size: 16px;
      line-height: 21px;
    }
  }

  .buttonClose {
    height: 14px;
    width: 14px;
    cursor: pointer;

    &__icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  max-width: 160px;
  width: 100%;
  justify-content: space-between;
}
