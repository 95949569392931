@import '~assets/styles/variables';

.chatInfo {
	position: relative;

	&__image {
		width: 54px;
		height: 54px;
		border-radius: 50%;
	}

	&__counter {
		width: 23px;
		height: 23px;
		background: $secondary-main;
		border: 1px solid #fff;
		border-radius: 50%;
		font-weight: 500;
		font-size: 11px;
		line-height: 22px;
		color: #fff;
		position: absolute;
		top: -3px;
		right: -3px;
		text-align: center;
		overflow: hidden;
	}
}

.onsuspend {
  cursor: not-allowed;
}
