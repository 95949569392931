.container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(241, 243, 255, 0.1);
	z-index: 88;

	.content {
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		&__title {
			margin: 0 auto;
			margin-top: 2.5vh;
			margin-bottom: 2vh;
		}

		button {
			margin-top: 2.5vh;
		}
	}
}
