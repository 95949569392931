@import '~assets/styles/mixins';

.container {
	@include onboardingForm;
	@include changeOpacityToVisibleAnimation;

	.skip {
		@include skipButton;
		cursor: pointer;
	}

	.title__wrapper {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		text-align: center;

		.title {
			line-height: 26px;
		}
		.sub_title {
			@include signUp-subtitle;
		}
	}

	.upload {
		font-size: 18px;
		line-height: 23px;
		margin-bottom: 3.5vh;
		text-decoration: underline;
		cursor: pointer;
	}
}
