@import "~assets/styles/variables";

.marketing_item {
  width: 299px;
  height: 423px;
  background-color: #fff;
  border: 1px solid $primary-bg;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.isCurrent {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $secondary-main;
}
.item_header {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  height: 63px;
  flex: 0 0 auto;
}
.item_main {
  flex: 1 0 auto;
  width: 297px;
  height: 301px;
  border-radius: 0;
}
.item_footer {
  flex: 0 0 auto;
  height: 59px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.item_user_post {
  display: flex;
}
.item_count {
  display: flex;
  align-items: center;
}
.user_logo {
  margin-right: 14px;
  border-radius: 50%;
  overflow: hidden;
  width: 38px;
  height: 38px;
  & > img {
    width: 38px;
    height: 38px;
    background-color: #cecece;
    object-fit: cover;
  }
}
.item_action,
.item_count {
  cursor: pointer;
}
.item_ico {
  margin-right: 8px;
}
.item_user_post {
  display: flex;
  align-items: center;
}
