@import '~assets/styles/variables';

.main {
	max-width: 56px;
	width: 100%;
	max-height: 56px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: $primary-light;
}
