.container {
	width: 100%;
	height: min-content;
}

.marketing_grid {
	display: flex;
	flex-wrap: wrap;
	gap: 38px;
}
