.stickyHeader {
    position: sticky; 
    top: -30px; 
    height: 55px; 
    z-index: 1;

    &.invited_users {
        top: 15px;
    }

    &__container {
        position: relative;
    }

    &__background {
        top: -55px;
        height: 60px;
        background-color: white;
        border-radius: 0;
        position: absolute;
        width: 100%;
    }
    &__content {
        position: absolute;
        width: 100%;
    }
}




