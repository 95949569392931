@import '~assets/styles/mixins';

.input__text {
  position: relative;

  input {
    @include inputMain;

    &:not(:focus) {
      & + .input__text_edit {
        opacity: 1;
      }
    }
  }

  &_edit {
    @include inputEdit;
  }
}
