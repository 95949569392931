@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
	@include onboardingForm;

	.title {
		display: block;
		margin: 0 auto;
		margin-top: 3.3vh;
		margin-bottom: 2.1vh;
	}
}
