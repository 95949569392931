h2 {
	align-self: flex-start;
}

.form__container {
	max-width: 530px;
	width: 100%;
	margin-top: 2.5vh;
}

.loader {
	width: 100%;
}
