@import '~assets/styles/mixins';

.container {
  @include orgPage;

  .label {
    @include popupLabel;
  }
}

.form {
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.email {
  display: flex;
  flex-direction: column;
  width: 100%;

  //span {
  //  font-size: 12px;
  //  line-height: 18px;
  //  letter-spacing: -0.2px;
  //  color: #d00074;
  //}
}

.mb_16 {
  margin-bottom: 16px;
}

.mb_0 {
  margin-bottom: 0;
}
