.wrapper {
	max-width: 1494px;
	width: 100%;
	min-height: 81.4vh;
	display: flex;
	background-color: #fff;
	box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
	border-radius: 10px;
	position: relative;

	&_right {
		width: 100%;
    padding: 4vh 20px 20px 8.6vw;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    flex-direction: column;

    &_right {
      display: flex;
      justify-content: center;
    }

    &_left {
      max-width: 100%;
    }
  }
}
