@import '~assets/styles/mixins';

.container {
	@include orgPage;
	display: flex;
	flex-direction: column;
	height: min-content;

	.wrapper {
		width: 100%;

		.content {
			width: 100%;
			display: flex;
			justify-content: space-between;

			&__main {
				width: 100%;
			}

			&__additional {
				flex-shrink: 0;
				max-width: 328px;
				width: 100%;
				margin-top: 60px;
				margin-left: 16px;
			}
		}
	}
}
