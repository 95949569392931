.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;

  &__field {
    margin-bottom: 10px;
  }

  &__opportunitie_address {
    display: flex;
    margin-bottom: 2.3vh;
    width: 100%;

    button {
      width: 48%;
    }

    button:first-child {
      margin-right: 12px;
    }
  }

  .label_for_button {
    cursor: pointer;

    input {
      display: none;
    }
  }

  .form__location_type {
    .label_for_button {
      width: 100%;
      margin-top: 10px;
      cursor: pointer;

      button {
        width: 100%;
      }
    }
  }

  .field_picker {
    align-self: flex-start;

    &.search_organisation {
      & > div {
        z-index: 60;
      }
    }
  }

  .formWrap {
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 10px;
    padding: 10px 25px 0;
    max-height: calc(100vh - 280px);
    height: 100%;
    overflow: auto;

    &__opportunitie_address {
      display: flex;
      margin: 2vh 0;
      width: 100%;
      justify-content: space-between;

      button {
        width: 46%;
      }
    }
  }

  .label {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 10px;
    margin-right: 25px;
    align-self: flex-start;
  }

  .field_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .top {
    z-index: 7;
  }
}

.input__search {
  width: 100%;
  padding: 0 0 10px 0;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.no_min_height {
  min-height: unset;
}
