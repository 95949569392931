@import '../../../assets/styles/variables';

.container {
  height: 200px;
  width: 100%;
  border: 1px solid $primary-light;
  box-sizing: border-box;
  border-radius: 30px;
  background: #ffffff;
  position: relative;

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;

    text {
      text-anchor: middle;
      dominant-baseline: middle;
      font-style: italic;
      font-family: 'Rubik', sans-serif;
      font-weight: 500;
      fill: black;
      opacity: 0.1;
    }
  }
}

.signatureCanvas {
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: url("../../../assets/image/pen2.png"), default;
}
