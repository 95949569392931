.helper__wrapper {
  position: absolute;
  top: 14px;
  left: -46px;
  &_text {
    font-size: 10px;
    line-height: 22px;
    color: #5b768b;
  }
}
