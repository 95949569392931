.main {
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;

    &__container {
      display: flex;
      align-items: center;
      gap: 12px;

      button:first-child {
        min-width: 30px;
      }
    }
  }

  .title {
    z-index: 2;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select {
    width: 250px;
  }

  button {
    width: auto !important;
  }

  .scrollIcon {
    cursor: pointer;
    position: fixed;
    opacity: 0;
    transition: opacity 0.3s ease;

    svg {
      width: 30px;
      height: 30px;
    }

    &_visible {
      opacity: 1;
    }
  }
}

.fullname {
  & > div:first-child {
    max-width: 260px;
  }
}

.completed_hours {
  max-width: 82px;
}
