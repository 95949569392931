@import '~assets/styles/mixins';

.form {
  @include onboardingForm;
  position: relative;

  &__password {
    margin-bottom: 16px;
  }

  &__info {
    position: absolute;
    top: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;

    & svg {
      width: 18px;
      height: 18px;

      & path {
        fill: #214ba5;
      }
    }
  }

  .button__container {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    margin-top: 3.5vh;
  }
}
