@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.main {
  @include changeOpacityToVisibleAnimation;
  margin-top: 120px;
  max-width: 360px;
  min-width: 360px;
  width: 100%;
  height: max-content;
  position: sticky;
  top: -26px;
  background-color: transparent;
  z-index: 10;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  max-height: 100vh;
  overflow-y: auto;

  .content {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .separator {
      width: 100%;
      border: none;
      height: 1px;
      background-color: $primary-bg;
    }

    &__filters {
      width: 100%;
      padding: 10px 16px;
    }
  }
}
