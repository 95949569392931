.form {
	width: 100%;
}

@media screen and (max-width: 1000px) {
  .form {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
