@import '~assets/styles/mixins';

.content {
  @include loginContent;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.4vh;
}

.subtitle {
  @include signUp-subtitle;
  text-align: center;
  padding: 5px 100px;
  line-height: 23px;
}

.button__container {
  max-width: 300px;
  width: 100%;
  margin-top: 4.4vh;
  display: flex;
  align-items: center;
}
