@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.form {
  @include onboardingForm;
  margin: 0 auto;

  & > div {
    min-height: 60px;
  }

  .buttons {
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5vh;
  }
}
