@import '~assets/styles/mixins';
@import '~assets/styles/variables';

.form {
  &.buttons button {
    width: 100% !important;
  }

  .label {
    @include popupLabel;

    &__unvisible {
      @include popupLabel;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__limitCheckbox_wrapper {
    display: flex;
  }

  &__hashtag__wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  &__hashtag_label,
  &__limitCheckbox_label {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: $text-main;
    margin: unset;
  }

  &__limit_wrapper {
    min-height: 90px;
    margin-top: 20px;
  }

  &__limit > span {
    position: absolute;
    left: 90px;
    bottom: 12px;
  }

  &__limit > label {
    font-weight: 400;
    font-size: 14px;
  }

  &__limit > div {
    width: 14%;
    position: relative;
    // Then for the Chrome browser
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // Firefox
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input[type='number']:hover,
    input[type='number']:focus {
      -moz-appearance: number-input;
    }
    // For others
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      padding: 8px 13px 8px 13px;
    }
  }

  &__join_type {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.55vh;
    margin-bottom: 3.45vh;
  }

  &__pickers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2vh;

    &_reset {
      margin-bottom: unset;
    }

    &_date {
      max-width: 165px;
      width: 100%;
    }

    &_dateSingle {
      max-width: 240px;
      width: 100%;
    }

    &_time {
      max-width: 345px;
      margin-left: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      z-index: 99;

      .timer {
        max-width: 165px;
        width: 100%;
      }
    }
  }

  .coordinator {
    div:first-child {
      z-index: 99;
    }
  }

  &__location {
    height: 45px;
    margin-bottom: 1.8vh;
    position: relative;

    .pac-container {
      left: 0 !important;
      top: 40px !important;
    }

    div {
      height: auto;
    }

    input {
      @include changeOpacityToVisibleAnimation;
    }

    .button_zoom,
    .button_zoom:hover {
      @include default-button;
      @include font-button;
      @include changeOpacityToVisibleAnimation;
      max-width: 280px;
      width: 100%;
      border: none;
      background-color: $primary-bg;
      color: $primary-main;

      &:active {
        background-color: $primary-light;
        color: $primary-dark;
      }

      &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  &__opportunitie_address {
    display: flex;
    margin-bottom: 2.3vh;

    button:first-child {
      margin-right: 12px;
    }
  }

  &__forWho {
    width: 100%;
    margin-bottom: 15px;

    .who__wrapper {
      max-width: 300px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .additional {
    &__title {
      cursor: pointer;
      margin-bottom: 2.3vh;

      &_arrow {
        margin-left: 10px;
        width: 10px;
        height: 10px;
        transform: rotateZ(90deg);
        transition: all 0.2s ease;

        &_active {
          transform: rotateZ(-90deg);
        }
      }
    }

    &__content {
      max-width: 533px;
      width: 100%;
      @include changeOpacityToVisibleAnimation;

      &_close {
        @include changeOpacityToUnvisibleAnimation;
      }

      .details__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .ageLimit {
          width: 100%;
          display: flex;
          justify-content: space-between;
          z-index: 60;

          & > div {
            max-width: 255px;
          }
        }
      }
    }
  }

  .notify__whatsapp {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #1c2f4c;
    margin-bottom: 20px;
    margin-top: -8px;

    & span {
      font-size: 14px;
    }
  }
}
