.container {
  height: min-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 10px;
  margin-right: 10px;
  margin-bottom: 24px;

  &.absolute {
    position: absolute;
    z-index: 5;
  }

  span {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }

  &.onboarding {
    position: absolute;
    z-index: 11;
    left: 11px;

    @media (max-width: 480px) {
      top: 16px;
    }
  }
}

@media (max-width: 480px) {
  .container span {
    display: none;
  }
}
