@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.form {
  height: 375px;
  width: 100%;
  max-width: 850px;
  padding: 25px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: $gradients-orange;

  &__title {
    font-size: 14px;
    font-weight: 700;
    color: #000000;

    &_blue {
      color: #214ba5;
    }
  }

  &__media {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
  }

  &__button {
    &_underlined {
      background: none;
      color: $primary-main;
      text-decoration: underline;
      font-size: 18px;
      font-weight: 400;
    }
  }
}
