@import '~assets/styles/mixins';
@import '~assets/styles/variables';

* {
  line-height: 1.2;
}

.container {
  flex-grow: 1;
  max-width: 1920px;
  container-type: inline-size;
}

@container (min-width: 0px) {
  .board {
    padding: 1.6cqw 7.2cqw;
    display: grid;
    grid-template-columns: repeat(3, 27cqw);
    grid-template-rows: auto repeat(2, 19.7cqw) 5.2cqw;
    grid-template-areas:
      'hd hd hd'
      'th to mb'
      'wh ss mb'
      'ft ft mb';
    grid-gap: 2cqw;
  }

  .top {
    grid-area: hd;
    width: 100%;
    padding-inline: 2cqw;
    display: flex;
    align-items: center;
    gap: 2cqw;

    &__organization {
      max-width: 50%;
      display: flex;
      align-items: center;
      gap: 1.6cqw;

      &__avatar {
        width: 5.2cqw;
        height: 5.2cqw;
        border-radius: 50%;
        object-fit: cover;
      }

      &__name {
        font-size: 2.5cqw;
        font-weight: 500;
        letter-spacing: -0.05cqw;
        word-wrap: break-word;
        @include multilineThreeDots(3);
      }
    }

    &__title {
      flex-grow: 1;
      font-size: 1.4cqw;
      word-wrap: break-word;
      color: $primary-main;
      @include multilineThreeDots(4);
    }
  }

  .card {
    border-radius: 2cqw;
    padding: 2.5cqw 1.6cqw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1cqw;
    background: #fff;

    &_first {
      grid-area: th;

      .card__count {
        color: $primary-main;
      }
    }

    &_second {
      grid-area: to;

      .card__count {
        color: $primary-main;
      }
    }

    &_third {
      grid-area: wh;

      .card__count {
        color: $secondary-main;
      }
    }

    &_forth {
      grid-area: ss;
    }

    &__title {
      justify-self: start;
      font-size: 2.5cqw;
      font-weight: 500;
    }

    &__count {
      margin-block: auto;
      max-width: 100%;
      font-size: 8.3cqw;
      font-weight: 600;
      @include lineThreeDots();
    }

    &__volunteer {
      align-self: flex-start;
      max-width: 100%;
      padding-inline: 1.6cqw;
      display: flex;
      gap: 1.6cqw;

      &__avatar {
        flex-shrink: 0;
        width: 4.1cqw;
        height: 4.1cqw;
        max-width: unset;
        max-height: unset;
        border-radius: 50%;
        object-fit: cover;
      }

      &__info {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.4cqw;
      }

      &__name {
        font-size: 1.6cqw;
        font-weight: 500;
        word-wrap: break-word;
        @include multilineThreeDots(2);
      }

      &__grade {
        font-size: 1.2cqw;
      }
    }

    &__subTitle {
      width: 100%;
      padding-inline: 1.6cqw;
      word-wrap: break-word;
      font-size: 1.6cqw;
      @include multilineThreeDots(2);

      &_bold {
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }

  .bottom {
    grid-area: ft;
    padding-inline: 0.8cqw 1.6cqw;
    border-radius: 1.6cqw;
    display: flex;
    align-items: center;
    gap: 16px;
    background: #fff;

    &__avatar {
      flex-shrink: 0;
      height: 4cqw;
      object-fit: cover;
    }

    &__subTitle {
      margin: 0.4cqw auto;
      font-size: 1.2cqw;
      color: $primary-main;
      @include multilineThreeDots(3);
    }
  }

  .media {
    grid-area: mb;

    &__file {
      width: 100%;
      height: 100%;
      border-radius: 1.6cqw;
      object-fit: cover;
    }
  }
}
