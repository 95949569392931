.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.date,
.time {
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.208px;
  text-overflow: ellipsis;
  overflow: hidden;
}
