.feed_status {
  grid-column: 1 / span 2;
  margin-top: 18px;
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  align-items: center;

  .status {
    display: flex;
    min-width: 130px;
    height: 22px;
    border-radius: 50px;
    display: flex;
    align-content: center;
    padding-right: 10px;

    &.pending {
      background: #fff4e4;
      .status__text {
        color: #f79d54;
      }
    }

    &.confirm {
      background: #f3f8e3;
      .status__text {
        color: #94cd00;
      }
    }

    &.completed {
      background: #6fc67347;
      .status__text {
        color: #388e3c;
      }
    }

    &.rejected {
      background: #fbeff7;
      .status__text {
        color: #d00074;
      }
    }
    &.cantMakeIt {
      background: #faccea;
      .status__text {
        color: #fff;
      }
    }

    &.closed {
      background: #8d8d8d;
      .status__text {
        color: #ffffff;
      }
    }

    &__icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    &__text {
      position: relative;
      font-size: 10px;
      line-height: 22px;
      margin-left: 7px;
      color: #fff;
    }
  }
}
