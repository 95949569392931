.content {
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.9vh;
  text-align: center;

  &__title {
    font-family: Rubik,sans-serif;
    font-size: 25px;
    font-weight: 500;
  }

  &__message {
    margin-top: 3vh;

    &__vertical {
      display: flex;
      flex-direction: column;
    }

    &_limit {
      max-width: 260px;
    }

    &_many_request {
      max-width: 360px;
    }
  }

  button {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
}
