@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.caption {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
  background: #f8f9ff;
  border-radius: 10px;

  &__hashtag {
    font-size: 22px;
    color: #4b5ef5;
    font-style: italic;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-height: 30px;
  }
}

.title {
  max-width: 72%;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  min-height: 24px;
  @include lineThreeDots();
}

.subtitle {
  max-width: 72%;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  @include lineThreeDots();

  &__description {
    padding-top: 35px;
    padding-bottom: 35px;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
    margin-bottom: 16px;
  }
}

.images {
  display: flex;
  padding-top: 35px;
  margin-bottom: 16px;
}

.image_container {
  width: 150px;
  height: 152px;
  margin-top: 10px;
  margin-right: 26px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid $primary-light02;
}

.broken_image {
  width: 150px;
  height: 152px;
  border-radius: 10px;
  object-fit: none;
  border: 2px solid $primary-light02;
  align-self: center;
}

.divider {
  width: 1px;
  height: 20%;
  margin: 6px 10px;
  background: $text-main;
}

.details_container {
  background: #f8f9ff;
  border-radius: 10px;
  padding: 15px 5px;
}

.main_data {
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;

  &:nth-child(3) {
    margin-top: 20px;
  }

  &__container {
    padding-top: 40px;
  }

  &__label {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding-right: 10px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }
}

.bottom {
  margin-top: 48px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 40px;

  .signature {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__image {
      width: 150px;
      object-fit: contain;
    }

    &__description {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 14px;
      text-transform: capitalize;
      color: $secondary-main;
    }
  }

  .actions {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;

    &__confirmation {
      display: flex;
      gap: 10px;
    }

    & > button {
      margin-left: auto;
    }

    .full__width {
      width: 100% !important;
    }
  }
}

.mainContent__subtitle {
  font-size: 18px;
  margin-top: 8px;
  font-weight: 500;
  color: $text-main;
}

.subtitle__description {
  padding: 6px 0 6px;
}
