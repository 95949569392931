@import '~assets/styles/mixins';

.main {
  max-width: 110px;
  min-height: 80px;
  max-height: 132px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 2px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & > span {
    padding: 5px 0;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.208px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  &.actionRejectButtons {
    justify-content: center;
  }
}

.chesedName {
  max-width: 110px;
  min-width: 110px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;

  span {
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  img {
    display: block;
    width: 16px;
    height: 14px;
    object-fit: contain;
    margin-left: 2px;
    align-self: center;
    flex: 0 0 22px;
  }
}

.age {
  max-width: 60px;
  width: 100%;
  justify-self: flex-start;
}

.gender {
  max-width: 80px;
  width: 100%;
  text-transform: capitalize;
  text-align: start;
}

.location {
  max-width: 110px;
  width: 100%;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    padding: unset;
    margin: 5px 0;
  }
}

.forms {
  max-width: 150px;
  width: 100%;
}

.email {
  max-width: 160px;
  width: 100%;
  max-width: fit-content;
}

.phone {
  width: 100%;
  max-width: fit-content;
}

.date {
  max-width: 100%;
  text-align: center;
  width: 100%;
  white-space: pre-line;
}

.time {
  max-width: 80px;
  width: 100%;
}

.duration {
  max-width: 64px;
  width: 100%;
}

.coordinator {
  max-width: 150px;
  width: 100%;
}

.fullName {
  max-width: 110px;
  width: 100%;
}

.options {
  max-width: 95px;
  min-width: 70px;

  button:last-child {
    margin-right: 0;
    margin-left: 5px;
  }
}

.schoolGrade {
  width: 100%;
}

.yearTimeSpend {
  width: 100%;
}

.amountOppo {
  width: 100%;
}

.total {
  width: 100%;
}

.position {
  max-width: 70px;
  width: 100%;
}

.role {
  max-width: 100px;
  width: 100%;
}

.monthly {
  text-align: start;
  justify-content: flex-start;
  padding: 0 15px;
}

.copy {
  margin-left: 10px;

  .icon {
    width: 20px;
    height: 20px;
    user-select: none;
    margin-left: 10px;
    cursor: pointer;
  }
}

.mark__wrapper {
  display: flex;
  max-width: 80px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 4px;
  gap: 3px;
}

.admin__mark {
  background-color: #ffbf00;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.3px;
  margin: 0;
  padding: 4px;
  border-radius: 8px;
  font-weight: 400;
}

.manual__mark {
  display: flex;
  align-items: center;
}

.previous_completed_mark {
  path {
    fill: #214ba5;
  }
}

.opportunity__name {
  cursor: pointer;
}

.warning {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  cursor: pointer;
  margin-right: 4px;

  path {
    fill: #D00074;
  }
}