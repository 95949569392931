@import "~assets/styles/variables";

.link,
link:visited {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-decoration: underline;
  color: $text-light;
  margin: 5px;
}
